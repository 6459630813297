import React, { createContext, useContext, useState, useEffect } from 'react';
import { isTokenExpired, saveToken, removeToken, getToken } from 'utils/auth';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwtDecode
import io from 'socket.io-client';

const AuthContext = createContext();
let socket = null; // Singleton instance for socket

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // State for user info including role
  const [loading, setLoading] = useState(true);

  // Helper function to initialize socket and handle events
  const initSocket = (decodedToken) => {
    if (!socket) {
      socket = io(process.env.REACT_APP_API_BASE_URL, {
        transports: ["websocket"],
        withCredentials: true,
        secure:true,
      }); 

      socket.on('connect', () => {
        console.log('Socket connected:', socket.id);

        // Emit joinRoom for the logged-in user
        if (decodedToken.id) {
          socket.emit('joinRoom', decodedToken.id);
          console.log(`User ${decodedToken.id} joined their room`);
        }
      });

      socket.on('disconnect', () => {
        console.log('Socket disconnected:', socket.id);
      });

      socket.on('connect_error', (err) => {
        console.error('Socket connection error:', err);
      });

      socket.on('reconnect', (attempt) => {
        console.log('Reconnected after', attempt, 'attempts');
      });
    }
  };

  useEffect(() => {
    const token = getToken();

    if (token && !isTokenExpired(token)) {
      const decodedToken = jwtDecode(token);
      setIsAuthenticated(true);
      setUser(decodedToken); // Set the user info based on decoded token

      console.log("User permissions after setting:", decodedToken.permissions);

      // Initialize socket connection
      initSocket(decodedToken);

      // Handle auto-logout when the token expires
      if (!localStorage.getItem('rememberMe')) {
        const timeUntilExpiration = decodedToken.exp * 1000 - Date.now();
        setTimeout(() => {
          logout();
        }, timeUntilExpiration);
      }
    } else {
      setLoading(false); // Ensure loading is set to false if not authenticated
    }

    return () => {
      // Cleanup socket connection on component unmount
      if (socket) {
        socket.disconnect();
        console.log('Socket disconnected during cleanup');
        socket = null; // Clear the socket instance
      }
    };
  }, []); // Run only on mount

  const login = (token, rememberMe) => {
    saveToken(token, rememberMe);
    const decodedToken = jwtDecode(token);
    setIsAuthenticated(true);
    setUser(decodedToken); // Set the user state on login

    console.log("User role on login:", decodedToken.role); // Log the user's role on login

    // Initialize socket connection after login
    initSocket(decodedToken);
  };

  const logout = () => {
    removeToken();
    setIsAuthenticated(false);
    setUser(null); // Clear the user state on logout

    // Disconnect the socket on logout
    if (socket) {
      socket.disconnect();
      console.log('Socket disconnected on logout');
      socket = null; // Clear socket instance
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
