import ActionButtons from "components/actions/ActionButtons";
import Card from "components/card";
import { useNavigate } from "react-router-dom";

const Invoice = ({ invoices }) => {
  const navigate = useNavigate();
  console.log("Invoices:", invoices)

  const handleViewInvoice = (invoiceId) => {
    const selectedInvoice = invoices.find((invoice) => invoice.id === invoiceId);
    
    if (selectedInvoice) {
      // Instead of fetching the invoice again, pass the invoice details directly
      console.log("Invoice data:", selectedInvoice);
      navigate(`/admin/account/invoice/${invoiceId}`, { state: { invoice: selectedInvoice } });  // Added invoiceId to URL
    } else {
      console.error("Invoice not found.");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <Card extra={"px-9 w-full h-full pt-[28px] pb-4"}>
      {/* Header */}
      <div className="mb-8 mt-3 flex items-center justify-between">
        <h5 className="text-lg font-bold text-navy-700 dark:text-white">
          Invoices
        </h5>
        <h5 className="font-bold text-brand-500 hover:cursor-pointer dark:text-white">
          See all invoices
        </h5>
      </div>

        {/* Map through invoices */}
        {invoices && invoices.length > 0 ? (
          invoices.map((invoice) => (
            <ActionButtons
              key={invoice.id}
              mb="mb-[42px]"
              name={invoice.invoiceNumber}  // Invoice Number
              date={formatDate(invoice.issueDate)}  // Formatted Issue Date
              sum={`Ksh ${invoice.totalAmount}`}  // Total Amount
              action={() => handleViewInvoice(invoice.id)}  // Action handler for viewing PDF
              actionName="View PDF"
            />
          ))
        ) : (
          <div className="flex flex-grow items-center justify-center min-h-[200px]">
            <p className="text-gray-500 dark:text-gray-400 text-center">
              No invoices found.
            </p>
        </div>
        )}
    </Card>
  );
};

export default Invoice;
