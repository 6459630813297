import React from "react";
import CardMenu from "components/card/CardMenu";
import {
  MdOutlineShoppingBasket,
  MdOutlineDirectionsBus,
  Md3DRotation,
  MdOutlineSchool,
} from "react-icons/md";

import Transaction from "components/dataDisplay/Transaction";
import Card from "components/card";
function Balance( { transactions }) {

  //Sort to the latest transactions
  const sortedTransactions = transactions.sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate));

  return (
    <Card extra={"px-4 py-6 w-full"}>
      <div className="bg-image relative mt-[7px] flex h-fit w-full justify-between rounded-xl bg-brand-900 bg-ballanceDashboard bg-cover bg-right px-3 py-4 text-white dark:bg-navy-700">
        <di>
          <p className="text-sm font-medium">Balance </p>
          <h4 className="text-3xl font-bold">Ksh 3942.58</h4>
        </di>
        <div className="flex flex-col items-end text-white">
          <CardMenu transparent />
          <div className="mt-5">
            <svg
              width="68"
              height="20"
              viewBox="0 0 68 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 8.2C1.70238 11.8 4.5 18.5 11 18C17.5 17.5 18.2786 1 29.6571 1C41.0357 1 41.0357 20.0286 60 3.57143"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <h5 className="mt-5 text-sm font-medium text-gray-600">Recent</h5>

      {/* Bill section */}
      <div className="">
        {sortedTransactions.slice(0, 2).map((transaction, index) => (
                  <Transaction
                    key={index}
                    title={transaction.courseTitle || "Transaction"}
                    date={new Date(transaction.paymentDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                    sum={`-Ksh ${transaction.totalPaid}`}
                    status={transaction.status}
                    mb="mb-5"
                    icon={
                      transaction.courseTitle ? (
                        <MdOutlineSchool className="text-brand-500 dark:text-white" />
                      ) : (
                        <Md3DRotation className="text-brand-500 dark:text-white" />
                      )
                    }
                  />
                ))}
      </div>
    </Card>
  );
}

export default Balance;
