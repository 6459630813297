import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SingleChat from "./components/SingleChat";
import CardMenu from "components/card/CardMenu";
import { BsPencilSquare } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import Chat from "./components/Chat";
import Card from "components/card";
import { fetchMessages, fetchTeamMembers, getUserProfile, markMessagesAsRead, fetchUsersAssignedToMentor } from "api";
import Loader from "components/loaders/Loader";
import { io } from 'socket.io-client';
import { useAuth } from "contexts/authContext";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const Messages = () => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [creatingNewChat, setCreatingNewChat] = useState(false);
  const searchInputRef = useRef(null);
  const [mentor, setMentor] = useState(null);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

  const location = useLocation();
  const { user } = useAuth();

  const queryClient = useQueryClient();

  // Fetch user profile
  const {
    data: userProfileData,
    isLoading: userProfileLoading,
    error: userProfileError,
  } = useQuery({
    queryKey: ["userProfile"],
    queryFn: getUserProfile,
  });

  const userProfile = userProfileData?.user;

  useEffect(() => {
    if (userProfile && userProfile.mentor) {
      setMentor(userProfile.mentor);
      console.log("Assigned Mentor details:", userProfile.mentor);
    }
  }, [userProfile]);

   // Fetch users including mentor
   const fetchUsers = async () => {
    if (userProfile) {
      let users = [];

      if (userProfile.role === "mentor") {
        users = await fetchUsersAssignedToMentor(userProfile.id);
      } else if (userProfile.team?.id) {
        users = await fetchTeamMembers(userProfile.team.id);
      }

      // Include mentor if not already included
      if (mentor && !users.some(user => user.id === mentor.id)) {
        users.push(mentor);
      }

      return users;
    }
    return [];
  };

  const {
    data: usersData,
    isLoading: usersLoading,
    error: usersError,
  } = useQuery({
    queryKey: ["users", userProfile?.id],
    queryFn: fetchUsers,
    enabled: !!userProfile,
    onSuccess: () => setIsInitialLoadComplete(true),
  });

  const {
    data: messagesData,
    isLoading: messagesLoading,
    error: messagesError,
  } = useQuery({
    queryKey: ["messages", userProfile?.id],
    queryFn: () => fetchMessages(userProfile.id),
    enabled: !!userProfile,
    refetchInterval: 5000, // Time to fetch
  });

  useEffect(() => {
    let socket;

    if (!socket) {
      socket = io(process.env.REACT_APP_API_BASE_URL, { transports: ["websocket"] });
    }

    socket.on("userStatusUpdate", ({ userId, status }) => {
      // Update user status only if initial load is complete
      if (isInitialLoadComplete) {
        queryClient.setQueryData(["users", userProfile?.id], (oldData) => {
          return oldData.map((user) =>
            user.id === userId ? { ...user, status } : user
          );
        });
        
        if (mentor && userId === mentor.id) {
          setMentor((prevMentor) => ({ ...prevMentor, status }));
        }
      }
    });

    return () => {
      if (socket) {
        socket.off("userStatusUpdate");
        socket.disconnect();
      }
    };
  }, [isInitialLoadComplete, userProfile, queryClient]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const chatWithId = queryParams.get("chatWith");

    if (chatWithId && usersData?.length > 0) {
      const chatUser = usersData.find((user) => user.id === parseInt(chatWithId));
      if (chatUser) {
        setSelectedUser(chatUser);
        setOpen(true);
        console.log("Selected Chat User", chatUser);
      }
    }
  }, [location.search, usersData]);

  // Prepare messages and users for display
  const allMessages = messagesData || [];
  const allUsers = usersData || [];

  // Filter messages for the selected user
  const messages = selectedUser
    ? allMessages
        .filter(
          (message) =>
            (message.sender.id === selectedUser.id && message.receiver.id === userProfile.id) ||
            (message.receiver.id === selectedUser.id && message.sender.id === userProfile.id)
        )
        .reverse()
    : [];

  // Mark messages as read
  useEffect(() => {
    if (messages.length > 0) {
      const unreadMessages = messages.filter(
        (message) => message.receiver.id === userProfile.id && !message.isRead
      );
      if (unreadMessages.length > 0) {
        markMessagesAsRead(unreadMessages.map((msg) => msg.id));
      }
    }
  }, [messages, userProfile]);

  // Handle mobile view scrolling
  useEffect(() => {
    if (open && window.innerWidth < 800) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "unset";
    }
  }, [open]);

  // Function to get the last message content for a user
  const getLastMessage = (userId) => {
    const lastMessage = allMessages
      .filter(
        (message) =>
          (message.sender.id === userId && message.receiver.id === userProfile.id) ||
          (message.receiver.id === userId && message.sender.id === userProfile.id)
      )
      .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      .pop();

    return lastMessage ? lastMessage.content : "No messages yet";
  };

  // Use the Loader component during loading states
  if (userProfileLoading || usersLoading || messagesLoading) {
    return <Loader />;
  }

  if (userProfileError || usersError || messagesError) {
    return <div>Error loading data</div>;
  }

  // Filter and sort users for display
  let filteredUsers = allUsers
    .filter(
      (user) =>
        user.id !== userProfile.id &&
        (userProfile.role === "mentor" ||
          (userProfile.role !== "mentor" &&
            (user.teamId === userProfile.teamId || user.id === mentor?.id)))
    )
    .sort((a, b) => (a.id === mentor?.id ? -1 : 1)); // Sort mentor to top if needed

  // Include mentor if not already included
  if (
    mentor &&
    userProfile.role !== "mentor" &&
    !filteredUsers.some((user) => user.id === mentor.id)
  ) {
    filteredUsers.unshift(mentor);
  }

  console.log("Filtered Users:", filteredUsers);

  const handleCreateNewChat = () => {
    setSelectedUser(null);
    setCreatingNewChat(true);
    setOpen(true);
    searchInputRef.current.focus();
  };

  return (
    <div className="mt-[80px] flex w-full grid-cols-10 flex-col gap-5 md:mt-[20px] lg:mt-6 lg:min-h-[85vh] lg:flex-row">
      <div className="w-full lg:w-[35%]">
        <Card extra={"w-full h-full p-4"}>
          {/* Header */}
          <div className="mb-3 flex items-center justify-between">
            <h1 className="font-dm text-[24px] font-bold text-navy-700 dark:text-white">
              Your Chats
            </h1>
            <CardMenu vertical />
          </div>
          {/* Search */}
          <div className="flex w-full items-center gap-2">
            <div className="flex h-[42px] w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-gray-100" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-gray-100 sm:w-fit"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                ref={searchInputRef}
              />
            </div>
            <button
              className="flex items-center justify-center rounded-full bg-brand-500 p-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-700 active:bg-blue-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
              onClick={handleCreateNewChat}
            >
              <BsPencilSquare />
            </button>
          </div>
          {/* Messages */}
          <div className="mt-4">
            {filteredUsers.length === 0 ? (
              <div className="text-center text-gray-500 dark:text-gray-300">
                No users found.
              </div>
            ) : (
              filteredUsers.map((user, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setOpen(true);
                    setSelectedUser(user);
                    setCreatingNewChat(false);
                    console.log("Selected User for Chat:", user);
                  }}
                >
                  <Chat
                    photo={
                      user.imageUrl
                        ? `${process.env.REACT_APP_API_BASE_URL}${user.imageUrl}`
                        : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'
                    }
                    active={user.status === 'Online'}
                    name={`${user.firstName || ''} ${user.lastName || 'Unknown User'}`.trim()}
                    time="Last seen"
                    message={getLastMessage(user.id)} // Display last message preview
                    isMentor={user.role === 'mentor'}
                  />
                </div>
              ))
            )}
          </div>
        </Card>
      </div>
      <div className="w-full lg:w-[64.5%]">
        {selectedUser && (
          <SingleChat
            open={open}
            onClose={() => setOpen(false)}
            user={selectedUser}
            creatingNewChat={creatingNewChat}
            userId={userProfile.id}
            userProfile={userProfile}
          />
        )}
      </div>
    </div>
  );
};

export default Messages;
