import { useEffect, useState } from "react";
import Information from "./components/Information";
import Connect from "./components/Connect";
import Delete from "./components/Delete";
import NewsLetter from "./components/NewsLetter";
import Password from "./components/Password";
import Profile from "./components/Profile";
import Session from "./components/Session";
import Social from "./components/Social";
import TwoFactor from "./components/TwoFactor";
import SaveChanges from "./components/SaveChanges";
import { getUserProfile, updateUser, uploadProfilePic, updatePassword, toggleTwoFactorAuth, deleteUser  } from "api";
import md5 from "md5";

const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const Setting = () => {
  const [profile, setProfile] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    imageUrl: '',
    username: '',
    school: '',
    aboutMe:'',
    twoFactorEnabled: false,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getUserProfile();
        const user = data.user;
        // Use Gravatar URL if no profile image is provided
        const imageUrl = user.imageUrl
          ? `${process.env.REACT_APP_API_BASE_URL}${user.imageUrl}`
          : getGravatarUrl(user.email);
        setProfile({ ...user, imageUrl });
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);


  // Handle profile update
  const handleProfileUpdate = async (updatedProfile) => {
    try {
      const updatedUser = await updateUser(profile.id, updatedProfile);
      setProfile(updatedUser.user); 
      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile");
    }
  };

  // Handle profile picture change
  const handleImageChange = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('profilePic', file);

      try {
        const response = await uploadProfilePic(formData);
        setProfile((prevProfile) => ({ ...prevProfile, imageUrl: response.imageUrl }));
        alert("Profile picture updated successfully!");
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        alert("Failed to upload profile picture");
      }
    }
  };

   // Handle password change
   const handlePasswordChange = async (newPassword) => {
    try {
      await updatePassword(profile.id, { newPassword });
      alert("Password updated successfully!");
    } catch (error) {
      console.error("Error changing password:", error);
      alert("Failed to change password");
    }
  };

  // Handle Two-Factor Authentication toggle
  const handleTwoFactorToggle = async (isEnabled) => {
    try {
      await toggleTwoFactorAuth(profile.id, { isEnabled });
      setProfile((prevProfile) => ({ ...prevProfile, twoFactorEnabled: isEnabled }));
      alert("Two-Factor Authentication updated successfully!");
    } catch (error) {
      console.error("Error toggling Two-Factor Authentication:", error);
      alert("Failed to update Two-Factor Authentication");
    }
  };

  // Handle account deletion
  const handleDeleteAccount = async () => {
    if (window.confirm("Are you sure you want to delete your account? This action is irreversible.")) {
      try {
        await deleteUser(profile.id);
        alert("Account deleted successfully!");
        // Redirect or update UI after deletion
      } catch (error) {
        console.error("Error deleting account:", error);
        alert("Failed to delete account");
      }
    }
  };

  // Handle saving changes
  const handleSaveChanges = () => {
    handleProfileUpdate(profile);
    // Optionally handle other save actions or validations here
  };

  if (loading) return <div>Loading...</div>;


  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        <Profile profile={profile} onImageChange={handleImageChange} />
        <Information profile={profile} onUpdate={handleProfileUpdate} />
        {/* <Social /> */}
        <Password onChange={handlePasswordChange}/>
      </div>

      <div className="flex flex-col gap-5">
        <TwoFactor isEnabled={profile.twoFactorEnabled} onToggle={handleTwoFactorToggle} />
        {/* <NewsLetter /> */}
        {/* <Session /> */}
        
        <Connect /> 
        <SaveChanges onSave={handleSaveChanges} />
        <Delete onDelete={handleDeleteAccount} />
      </div> 
    </div>
  );
};

export default Setting;
