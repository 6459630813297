export const tableColumnsReceipt = [
  {
    Header: 'Item',
    accessor: 'item',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Rate',
    accessor: 'rate',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
];
