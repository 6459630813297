export const columnsDataOrders = [
  {
    Header: 'PRODUCT NAME',
    accessor: 'name',
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
  {
    Header: 'STATUS ORDER',
    accessor: 'status',
  },
  {
    Header: 'TOTAL PRICE',
    accessor: 'price',
  },
  {
    Header: 'ORDER ACTIONS',
    accessor: 'actions',
  },
];
