import EventCalendar from "components/calendar/EventCalendar";
import Event from "../Calendar/components/Event";
import TimeLine from "../Calendar/components/TimeLine";
import { fetchTimelines, getUserProfile } from "api";
import { useEffect, useState } from "react";
import Loader from "components/loaders/Loader";

const Calendar = () => {
  const [calendarData, setCalendarData] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setLoading(true);
        const profile = await getUserProfile();
        setUserProfile(profile.user);
      } catch (error) {
        console.error("Error loading user profile:", error);
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, []);

  useEffect(() => {
    const loadCalendarData = async () => {
      if (!userProfile) return;

      try {
        setLoading(true);
        const timelines = await fetchTimelines();
        console.log("Fetched timelines:", timelines); // Debugging line

        const filteredTimelines = timelines.filter(timeline => 
          timeline.team && timeline.team.schoolName === userProfile.schoolName
        );
        
        const formattedData = filteredTimelines.map(timeline => {
          let startDate = new Date(timeline.startDate);
          let endDate = new Date(timeline.endDate);

          // Logic for special cases based on schoolName
          if (userProfile.schoolName === 'Itqan Australian Academy') {
            startDate.setDate(startDate.getDate() + (5 - startDate.getDay() + 7) % 7); // Find next Friday
            startDate.setHours(0, 0, 0); // Midnight
            endDate = new Date(startDate);
            endDate.setHours(23, 59, 59); // End of the day
          } else if (userProfile.schoolName === 'Little Innovators School') {
            startDate.setDate(startDate.getDate() + (1 - startDate.getDay() + 7) % 7); // Find next Monday
            startDate.setHours(0, 0, 0); // Midnight
            endDate = new Date(startDate);
            endDate.setHours(23, 59, 59); // End of the day
          } else if (userProfile.schoolName === 'MIT') {
            startDate.setDate(startDate.getDate() + (4 - startDate.getDay() + 7) % 7); // Find next Thursday
            startDate.setHours(0, 0, 0); // Midnight
            endDate = new Date(startDate);
            endDate.setHours(23, 59, 59); // End of the day
          }

          return {
            title: timeline.title.replace(`${timeline.team.schoolName} `, ""), // Remove school name from title
            start: startDate.toISOString().split('T')[0], // Only date part
            end: endDate.toISOString().split('T')[0], // Only date part
            borderColor: 'transparent',
            backgroundColor: '#7551FF', // You can set different colors based on your logic
            className: 'info' // You can set class names based on your logic
          };
        });
        setCalendarData(formattedData);
      } catch (error) {
        console.error("Error loading calendar data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadCalendarData();
  }, [userProfile]);

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  if (loading) return <Loader />;

  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 lg:grid-cols-11 gap-5 rounded-[20px]">
      <div className="col-span-1 lg:col-span-4 w-full rounded-[20px]">
        <div>
          <TimeLine />
        </div>
        <div className="mt-5">
          <Event />
        </div>
      </div>
      {/* Calendar */}
      <div className="z-0 col-span-1 lg:col-span-7 h-full w-full rounded-[20px] bg-white bg-clip-border px-[24px] py-[20px] shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none">
        {/* Calendar Header */}
        <div>
          <h5 className="text-xl font-bold text-navy-700 dark:text-white">
            Calendar
          </h5>
          <p className="mt-1 text-base font-medium text-gray-600 dark:text-white">
            {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
          </p>
        </div>
        <div className="mt-[28px] h-[700px] w-full rounded-[20px] dark:text-white">
          <EventCalendar initialDate={getCurrentDate()} calendarData={calendarData} />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
