import { useState } from "react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";


const Information = ({ profile, onUpdate }) => {
  const [updateProfile, setUpdateProfile] = useState({
    username: profile.username,
    email: profile.email,
    firstName: profile.firstName,
    lastName: profile.lastName,
    school: profile.school,
    aboutMe: profile.aboutMe || '',
  });

const handleChange = (e) => {
  const {id, value} = e.target;
  setUpdateProfile((prev) => ({ ...prev, [id]: value}));
};

const handleSave = () => {
  onUpdate(updateProfile); 
};

  return (
    <Card extra={"w-full !p-5"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Account Settings
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change user account information
        </p>
      </div>
      {/* inputs */}
      <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
        <InputField
          label="Username"
          placeholder="username"
          id="username"
          type="text"
          value={updateProfile.username}
          onChange={handleChange}
        />
        <InputField
          label="Email Address"
          placeholder="johndoe@gmail.com"
          id="email"
          type="text"
          value={updateProfile.email}
          onChange={handleChange}
        />
        <InputField
          label="First Name"
          placeholder="Vlad"
          id="firstName"
          type="text"
          value={updateProfile.firstName}
          onChange={handleChange}
        />
        <InputField
          label="Last Name"
          placeholder="Mihalache"
          id="lastName"
          type="text"
          value={updateProfile.lastName}
          onChange={handleChange}
        />
      </div>
      {/* full width inputs */}

      <InputField
        label="School"
        placeholder="school name"
        id="school"
        type="text"
        extra="mb-2"
        value={updateProfile.school}
        onChange={handleChange}
      />
      <TextField
        label="About Me"
        placeholder="Tell something about yourself in 150 characters!"
        id="textarea"
        cols="30"
        rows="7"
        value={updateProfile.aboutMe}
        onChange={handleChange}
      />
      {/* Save Button */}
      <div className="mt-4 flex justify-end">
        <button
          className="rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleSave}
        >
          Save Changes
        </button>
      </div>
    </Card>
  );
};

export default Information;
