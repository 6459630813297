import React, { useState, useEffect } from "react";
import {
  MdFlag,
  MdCheck,
  MdChevronRight,
  MdLock,
  MdExpandLess,
  MdExpandMore,
  MdPlayArrow,
  MdHourglassEmpty,
} from "react-icons/md";
import Card from "components/card";
import CircularProgressMini from "components/charts/CircularProgressMini";
import LaunchPoap from "assets/img/profile/LaunchPoap.png";
import ErrorAlerts from "components/alerts/ErrorAlerts";
import SuccessAlerts from "components/alerts/SuccessAlerts";
import { fetchTeamStepStatus, createApprovalRequest, getUserProfile } from "api";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

const Completed = ({ poap, poapIndex, teamId, school, userId }) => {
  const { id: descriptionId, title, learnings = '', totalPoints = 0, categories = [] } = poap || {};

  const [expandedTopics, setExpandedTopics] = useState({});
  const [expandedSteps, setExpandedSteps] = useState({});
  const [loadingSteps, setLoadingSteps] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [waitingApprovalSteps, setWaitingApprovalSteps] = useState(() => {
    const saved = localStorage.getItem('waitingApprovalSteps');
    return saved ? JSON.parse(saved) : {};
  });

  const validCategories = categories.map(category => ({
    name: category.name || "Unnamed Category",
    steps: category.steps ? category.steps.sort((a, b) => a.id - b.id) : []
  }));

  const queryClient = useQueryClient();

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(null), 3000);
      return () => clearTimeout(timer); // Cleanup on unmount or successMessage change
    }
  }, [successMessage]);

  // Fetch user profile and mentor ID
  const { data: userProfileData, isLoading: userProfileLoading, error: userProfileError } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
  });
  const userProfile = userProfileData?.user;
  const mentorId = userProfile?.mentor?.id;

  // Fetch team step statuses
  const {
    data: teamStepsData,
    isLoading: teamStepsLoading,
    error: teamStepsError,
  } = useQuery({
    queryKey: ['teamSteps', teamId, descriptionId],
    queryFn: () => fetchTeamStepStatus(teamId, descriptionId),
    enabled: !!teamId && !!descriptionId,
    refetchInterval:10000,
    refetchOnWindowFocus: true,
  });

  // Process teamStepsData into teamSteps and waitingApprovalSteps
  const [teamSteps, setTeamSteps] = useState({});
  useEffect(() => {
    if (teamStepsData) {
      const stepsMap = teamStepsData.reduce((acc, step) => {
        acc[step.poapStepId] = step.status;

        if (step.status === "waiting_for_approval") {
          setWaitingApprovalSteps(prevState => {
            const updatedState = { ...prevState, [step.poapStepId]: true };
            localStorage.setItem('waitingApprovalSteps', JSON.stringify(updatedState));
            return updatedState;
          });
        }
        return acc;
      }, {});
      setTeamSteps(stepsMap);
    }
  }, [teamStepsData]);

  // Remove steps from waitingApprovalSteps when they become 'completed'
  useEffect(() => {
    if (teamSteps) {
      Object.keys(teamSteps).forEach((stepId) => {
        if (teamSteps[stepId] === "completed") {
          clearApprovalStep(stepId);
        }
      });
    }
  }, [teamSteps]);

  // Mutation for creating approval requests
  const { mutateAsync: createApprovalRequestMutation } = useMutation({
    mutationFn: ({ stepId, teamId, userId, mentorId }) => createApprovalRequest(stepId, teamId, userId, mentorId),
    onSuccess: () => {
      queryClient.invalidateQueries(['teamSteps', teamId, descriptionId]);
      setSuccessMessage("Approval request sent successfully.");
    },
    onError: (error) => {
      console.error("Error sending approval request:", error);
      setErrorMessages(["Error sending approval request. Please try again."]);
    },
  });

  const handleRequestApproval = async (stepId, stepName) => {
    if (waitingApprovalSteps[stepId]) return;

    setLoadingSteps(prevState => ({
      ...prevState,
      [stepId]: true,
    }));
    setErrorMessages([]);
    setSuccessMessage(null);

    try {
      if (!mentorId) {
        throw new Error("Mentor ID not assigned.");
      }

      await createApprovalRequestMutation({ stepId, teamId, userId, mentorId });

      setWaitingApprovalSteps(prevState => {
        const updatedState = { ...prevState, [stepId]: true };
        localStorage.setItem('waitingApprovalSteps', JSON.stringify(updatedState));
        return updatedState;
      });
    } catch (error) {
      // Error handling is done in onError of mutation
    } finally {
      setLoadingSteps(prevState => ({
        ...prevState,
        [stepId]: false,
      }));
    }
  };

  // Helper function to remove a step from waitingApprovalSteps after approval
  const clearApprovalStep = (stepId) => {
    setWaitingApprovalSteps(prevState => {
      const updatedState = { ...prevState };
      delete updatedState[stepId];
      localStorage.setItem('waitingApprovalSteps', JSON.stringify(updatedState));
      return updatedState;
    });
  };

  const toggleTopic = (catIndex) => {
    setExpandedTopics(prevState => ({
      ...prevState,
      [catIndex]: !prevState[catIndex],
    }));
  };

  const toggleStep = (catIndex, stepIndex) => {
    const key = `${catIndex}-${stepIndex}`;
    setExpandedSteps(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const areAllStepsCompleted = (steps) => {
    return steps.every(step => teamSteps[step.id] === "completed");
  };

  const isStepUnlocked = (categorySteps, stepIndex) => {
    if (stepIndex === 0) {
      return true;
    }
    const previousStep = categorySteps[stepIndex - 1];
    const previousStepStatus = teamSteps[previousStep.id];
    return previousStepStatus === 'completed';
  };

  const getStepStatusIcon = (status, unlocked, isCurrentStep, stepId) => {
    if (!unlocked) return <MdLock />;
    if (status === "completed") return <MdCheck />;
    if (waitingApprovalSteps[stepId]) return <MdHourglassEmpty className="animate-spin" />;
    if (isCurrentStep) return <MdPlayArrow />;
    return <MdLock />;
  };

  const calculateStepProgress = (status, unlocked, isCurrentStep) => {
    if (!unlocked) return 0;
    if (status === "completed") return 100;
    return 0;
  };

  const isCurrentPoapCompleted = validCategories.every(category =>
    areAllStepsCompleted(category.steps)
  );

  useEffect(() => {
    if (poap) {
      poap.isCompleted = isCurrentPoapCompleted;
    }
  }, [isCurrentPoapCompleted, poap]);

  const handleCloseError = () => {
    setErrorMessages([]);
  };

  const handleCloseSuccess = () => {
    setSuccessMessage(null);
  };

  return (
    <div>
      {errorMessages.length > 0 && <ErrorAlerts errors={errorMessages} onClose={handleCloseError} />}
      {successMessage && <SuccessAlerts message={successMessage} onClose={handleCloseSuccess} />}
      <Card extra={"w-full p-4"}>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">{title}</h4>
        <div className="mt-3 flex items-center justify-between">
          <p className="text-base font-medium uppercase text-gray-600">
            {validCategories.length} {validCategories.length > 1 ? "Topics" : "Topic"}
          </p>
          <p className={`text-lg ${isCurrentPoapCompleted ? "text-green-500" : "text-orange-500"}`}>
            <MdFlag />
          </p>
        </div>
        <div className="mt-[20px] grid w-full grid-cols-5 gap-2">
          {validCategories.map((category, index) => (
            <div
              key={index}
              className={`h-2 w-full rounded-[36px] ${areAllStepsCompleted(category.steps) ? "bg-green-500" : "bg-orange-500"}`}
            />
          ))}
        </div>

        <div>
          {validCategories.map((category, catIndex) => (
            <div key={catIndex}>
              <h5
                className="mt-4 text-lg font-bold text-navy-700 dark:text-white flex items-center justify-between cursor-pointer"
                onClick={() => toggleTopic(catIndex)}
              >
                {category.name}
                {category.steps.length > 1 && (
                  expandedTopics[catIndex] ? <MdExpandLess /> : <MdExpandMore />
                )}
              </h5>
              {(expandedTopics[catIndex] || category.steps.length === 1) && (
                <div>
                  {category.steps.map((step, stepIndex) => {
                    const unlocked = isStepUnlocked(category.steps, stepIndex);
                    const stepKey = `${catIndex}-${stepIndex}`;
                    const status = teamSteps[step.id] || 'not started';
                    const isCurrentStep = unlocked && status !== 'completed';

                    return (
                      <div key={stepIndex}>
                        <div
                          className="mt-[19px] flex items-center justify-between gap-2"
                          onClick={() => unlocked && toggleStep(catIndex, stepIndex)}
                        >
                          <div className="flex gap-2">
                            <div className="w-[30px]">
                              <CircularProgressMini
                                step={stepIndex + 1}
                                percentage={calculateStepProgress(status, unlocked, isCurrentStep)}
                                color={isCurrentStep ? "orange" : status === "completed" ? "green" : "gray"}
                              />
                            </div>
                            <div className="flex items-center gap-1 font-medium text-gray-600 dark:text-white">
                              <p>{step.step} <b>({step.points} points)</b></p>
                              <p className="mt-1 text-lg">
                                {getStepStatusIcon(status, unlocked, isCurrentStep, step.id)}
                              </p>
                            </div>
                          </div>
                          <p className="text-2xl text-gray-600 dark:text-white">
                            {expandedSteps[stepKey] && unlocked ? <MdExpandLess /> : <MdChevronRight />}
                          </p>
                        </div>
                        {expandedSteps[stepKey] && unlocked && (
                          <div className="ml-8 mt-2 text-sm text-gray-600 dark:text-gray-300">
                            {step.detail}
                            {status !== "completed" && isCurrentStep && (
                              <div className="mt-2">
                                <button
                                  className="text-sm text-brand-500 hover:underline focus:outline-none flex items-center gap-1"
                                  onClick={() => handleRequestApproval(step.id, step.step)}
                                  disabled={loadingSteps[step.id] || waitingApprovalSteps[step.id]}
                                >
                                  {loadingSteps[step.id] || waitingApprovalSteps[step.id] ? (
                                    <>
                                      <MdHourglassEmpty className="animate-spin" />
                                      Waiting for Approval
                                    </>
                                  ) : (
                                    "Request Approval"
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-8">
          <h5 className="text-xl font-bold text-navy-700 dark:text-white">What you’ll learn</h5>
          <p className="mt-3 text-navy-700 dark:text-white">{learnings}</p>
        </div>

        <div className="mt-[52px]">
          <h5 className="mb-3 text-xl font-bold text-navy-700 dark:text-white">By the numbers</h5>
          <div className="flex w-max flex-wrap items-center justify-between gap-5 lg:w-full">
            <div>
              <div className="flex items-center gap-1">
                <h5 className="text-gray-600">Total Points:</h5>
                <p className="font-medium text-navy-700 dark:text-white">{totalPoints}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[52px]">
          <h5 className="text-xl font-bold text-navy-700 dark:text-white">Innovator's Badge</h5>
          <div className="mt-3 w-full rounded-[20px] bg-lightPrimary dark:bg-navy-700 p-3 shadow-2xl shadow-lightPrimary dark:shadow-none">
            <img className="w-full rounded-[20px]" src={LaunchPoap} alt={title} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Completed;
