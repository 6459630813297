import React from "react";

const SingleMessage = ({ message, time, isSent }) => {
  const messageClasses = isSent
    ? "bg-orange-500 text-white rounded-l-xl rounded-br-xl dark:bg-orange-700"
    : "bg-navy-600 text-white rounded-r-xl rounded-bl-xl dark:bg-gray-700 dark:text-white";

  const timeClasses = isSent
    ? "text-white dark:text-gray-300"
    : "text-gray-500 dark:text-gray-300";

  const formattedTime = new Date(time).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <div className={`mb-3 p-5 ${messageClasses}`}>
      <h1 className="text-md pb-2">{message}</h1>
      <p className={`text-sm font-medium ${timeClasses}`}>{formattedTime}</p>
    </div>
  );
};

export default SingleMessage;
