import Card from "components/card";

const SaveChanges = ({ onSave }) => {
  return (
    <Card extra="!flex-row py-6 px-[30px] justify-between items-center w-full">
      <div>
        <h3 className="mb-px text-xl font-bold text-navy-700 dark:text-white">
          Save Account Changes
        </h3>
        <p className="text-base text-gray-600">
          Make sure to save any changes made to your account information.
        </p>
      </div>
      <button
        onClick={onSave} 
        className="flex cursor-pointer items-center justify-center rounded-xl border-2 border-green-500 px-4 py-2 text-base font-medium text-green-500 transition duration-200 hover:bg-green-50 active:bg-green-100 dark:active:bg-green-50 dark:active:opacity-80"      
      >
        Save Changes
      </button>
    </Card>
  );
};

export default SaveChanges;
