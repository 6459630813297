import { IoClose } from "react-icons/io5";

const DescriptionModal = ({ onClose }) => {
  const courseTitle = "3D Printing with PrintLab";
  const courseDescription = `
    The 3D Printing course by PrintLab is a comprehensive introduction to 3D design and printing technology. 
    This course is ideal for students looking to develop skills in CAD software such as Tinkercad and Fusion 360. 
    Through a variety of interactive lessons and hands-on projects, students will explore the entire process of 
    creating 3D models and printing functional designs. The curriculum encourages creativity, collaboration, and problem-solving.
  `;

  const curriculum = `
    **Curriculum Overview**:
    
    1. **Introduction to 3D Printing**:
       - Learn the fundamentals of 3D printing and how it is revolutionizing industries.
    
    2. **3D Design with Tinkercad**:
       - Beginner-friendly lessons in Tinkercad, covering the basics of 3D modeling and design thinking.
    
    3. **Intermediate Design with Fusion 360**:
       - Dive into more advanced modeling with Fusion 360, focusing on creating detailed and complex designs.
    
    4. **Assistive Device Projects**:
       - Work on meaningful design challenges like creating assistive devices that help improve lives.
    
    5. **3D Printing Applications**:
       - Explore various applications of 3D printing across different industries, from engineering to healthcare.
    
    6. **Make:able Challenge**:
       - Participate in this global design challenge to create assistive technology for people with disabilities.
    
    7. **Final Project**:
       - Apply all the skills learned to design and print a personalized project, showcasing creativity and innovation.
  `;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-sm p-6">
      <div className="relative bg-white dark:bg-navy-800 rounded-xl shadow-lg max-w-3xl w-full max-h-[90vh] p-8 transition-transform transform duration-300 ease-in-out overflow-y-auto">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white"
        >
          <IoClose size={24} />
        </button>

        {/* Modal Header */}
        <div className="mb-4">
          <h2 className="text-3xl font-bold text-navy-700 dark:text-white">
            {courseTitle}
          </h2>
        </div>

        {/* Course Description */}
        <div className="mb-6">
          <p className="text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
            {courseDescription}
          </p>
        </div>

        {/* Curriculum */}
        <div className="border-t border-gray-300 dark:border-gray-600 pt-4">
          <h3 className="text-xl font-semibold text-navy-700 dark:text-white mb-3">
            Curriculum Overview
          </h3>
          <p className="text-base text-gray-700 dark:text-gray-300 whitespace-pre-line">
            {curriculum}
          </p>
        </div>

        {/* Close Modal Button */}
        <div className="mt-8 text-right">
          <button
            onClick={onClose}
            className="text-sm bg-orange-500 hover:bg-orange-600 text-white px-6 py-3 rounded-md transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DescriptionModal;
