import React, { useState } from "react";
import md5 from "md5"; // Import md5 for Gravatar
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { FiCamera } from "react-icons/fi";
import ErrorAlerts from "components/alerts/ErrorAlerts";
import SuccessAlert from "components/alerts/SuccessAlerts";

const MAX_FILE_SIZE = 5 * 1024 * 1024;

// Function to generate Gravatar URL
const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const Banner = ({ userProfile, poapData, onChangeProfilePicture }) => {

  const SERVER_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);

  const imageUrl = userProfile.imageUrl
    ? `${SERVER_BASE_URL}${userProfile.imageUrl}` 
    : getGravatarUrl(userProfile.email);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessages(["File is too large. Please upload a file smaller than 5MB."]);
        return;
      }

      setErrorMessages([]);

      try {
        await onChangeProfilePicture(file);
        setSuccessMessage("Profile picture updated successfully!");
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        setErrorMessages(["Failed to upload profile picture. Please try again."]);
      }
    }
  };

  return (
    <div>
      {errorMessages.length > 0 && (
        <ErrorAlerts errors={errorMessages} onClose={() => setErrorMessages([])} />        
      )}
      {successMessage && (
        <SuccessAlert message={successMessage} onClose={() => setSuccessMessage(null)} />
      )}

      <Card extra="items-center w-full h-full p-4 bg-cover">
        <div
          className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div
            className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700"
          >
            <img 
              className="h-full w-full rounded-full object-cover" 
              src={imageUrl}
              alt="Profile" 
            />
            {/* Edit Icon Overlay */}
            <div className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity cursor-pointer">
              <label htmlFor="fileInput" className="flex items-center justify-center text-white cursor-pointer">
                <FiCamera size={24} />
              </label>
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
              />
            </div>       
          </div>
        </div>

        <div className="mt-16 flex flex-col items-center text-center">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white md:text-xl">
            {`${userProfile.firstName} ${userProfile.lastName}`}
          </h4>
          <h5 className="text-sm font-normal text-gray-600 dark:text-gray-300 md:text-base">
            {userProfile.team ? userProfile.team.name : "No Team Assigned"}
          </h5>
        </div>

        <div className="mt-6 mb-3 flex flex-col md:flex-row gap-4 justify-center md:gap-8 lg:gap-10 xl:gap-16">
          <div className="flex flex-col items-center justify-center">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white md:text-2xl">
              {poapData.tasksCompleted}
            </h4>
            <p className="text-xs font-normal text-gray-600 dark:text-gray-300 md:text-sm">Completed</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white md:text-2xl">
              {poapData.poapCount}
            </h4>
            <p className="text-xs font-normal text-gray-600 dark:text-gray-300 md:text-sm">POAPs</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white md:text-2xl">
              {poapData.poapLevel}
            </h4>
            <p className="text-xs font-normal text-gray-600 dark:text-gray-300 md:text-sm">POAP Level</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Banner;
