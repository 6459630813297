import { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { MdGroup } from "react-icons/md";
import { FaSchool } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { checkTeamName, getUserProfile } from "api"; // Import the checkTeamName and getUserProfile functions

const TeamInfo = ({ setTeamData, teamData, setHasError }) => {
  const [teamNameError, setTeamNameError] = useState("");
  const [userSchool, setUserSchool] = useState("");

  useEffect(() => {
    // Fetch user profile to get the user's school
    const fetchUserSchool = async () => {
      try {
        const response = await getUserProfile();
        const school = response.user.school;
        setUserSchool(school);
        setTeamData((prevData) => ({ ...prevData, schoolName: school }));
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserSchool();
  }, [setTeamData]);

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setTeamData((prevData) => ({ ...prevData, [id]: value }));

    if (id === "name") {
      // Check if the team name already exists
      try {
        const response = await checkTeamName(value);
        if (response.exists) {
          setTeamNameError("Team name already exists. Please choose another name.");
          setHasError(true); // Notify parent about the error
        } else {
          setTeamNameError("");
          setHasError(false); // Notify parent about the resolved error
        }
      } catch (error) {
        console.error("Error checking team name:", error);
        setTeamNameError("Error checking team name.");
        setHasError(true); // Notify parent about the error
      }
    }
  };

  return (
    <div className="h-full w-full p-6 bg-white dark:bg-navy-700 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
      <form>
        {/* Header */}
        <h4 className="flex items-center gap-2 mb-6 text-2xl font-bold text-navy-700 dark:text-white">
          <MdGroup className="text-3xl" /> Team Info
        </h4>
        {/* Content */}
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          <div className="space-y-4">
            <InputField
              extra="w-full transition-shadow duration-300 ease-in-out hover:shadow-lg"
              label="Team Name"
              placeholder="e.g., The Innovators"
              id="name"
              type="text"
              value={teamData.name || ""}
              onChange={handleChange}
              icon={<MdGroup className="text-xl" />}
              error={teamNameError} // Pass the error message
              state={teamNameError ? "error" : ""}
            />
            <InputField
              extra="w-full transition-shadow duration-300 ease-in-out hover:shadow-lg"
              label="School Name"
              id="schoolName"
              type="text"
              value={userSchool || ""}
              icon={<FaSchool className="text-xl" />}
              readOnly // Make the field read-only
            />
          </div>
          <div>
            <TextField
              extra="w-full transition-shadow duration-300 ease-in-out hover:shadow-lg"
              label="Description"
              placeholder="Short description about the team!"
              id="description"
              value={teamData.description || ""}
              onChange={handleChange}
              cols="30"
              rows="7"
              icon={<AiOutlineInfoCircle className="text-xl" />}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TeamInfo;
