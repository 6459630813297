import md5 from "md5";
import { useNavigate } from "react-router-dom";

const SERVER_BASE_URL = process.env.REACT_APP_API_BASE_URL

const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`
};



const Instructor = ({ mentor }) => {
  const navigate = useNavigate();

  
  if (!mentor) {
    return <p>No mentor information available</p>
  };

  const getMentorImageUrl = (mentor) => {
    if (mentor.imageUrl) {
      return `${SERVER_BASE_URL}${mentor.imageUrl}`;
    } else {
      return getGravatarUrl(mentor.email || '');
    }
  };

  const handleSeeAllCourses = () => {
    navigate('/admin/courses')
  };

  return (
    <div className="w-full rounded-xl bg-lightPrimary px-[20px] py-[20px] dark:bg-navy-700">
      <h4 className="text-xl font-bold text-navy-700 dark:text-white">
        About Instructor
      </h4>
      <div className="mt-[14px] flex items-center gap-[17px]">
        <div className="flex h-[52px] w-[52px] items-center justify-center rounded-full">
          <img
            className="h-full w-full rounded-full object-cover"
            src={getMentorImageUrl(mentor)}
            alt={`${mentor.firstName} ${mentor.lastName}`}
          />
        </div>
        <div className="">
          <h5 className="text-base font-bold leading-6 text-navy-700 dark:text-white">
            {mentor.firstName} {mentor.lastName}
          </h5>
          <p className="text-sm font-medium text-gray-600">
            Mentor
          </p>
        </div>
      </div>
      <p className="mt-[18px] leading-6 text-navy-700 dark:text-white">
      John M is a robotics expert specializing in practical 3D printing. He teaches hands-on techniques and cutting-edge technologies, making complex concepts accessible and engaging for students.
      </p>
      <button 
      onClick={handleSeeAllCourses}
      className="mt-4 w-full rounded-full bg-brand-500 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
        See all courses
      </button>
    </div>
  );
};

export default Instructor;
