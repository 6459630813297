import Card from "components/card";
import Switch from "components/switch";
import React from "react";

function MentorNotifications() {
  const notifications = [
    { id: "switch1", label: "Step Completion Request Notifications" },
    { id: "switch2", label: "Team Assignment Notifications" },
    { id: "switch3", label: "3D Printing Guidance Request Notifications" },
    { id: "switch4", label: "Event or Deadline Reminder Notifications" },
    { id: "switch5", label: "General Announcements" },
  ];

  return (
    <Card extra={"w-full h-full p-3"}>
      <div className="relative mb-3 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Notifications
        </h4>
      </div>
      <div className="flex flex-col gap-4 mt-5">
        {notifications.map((notification) => (
          <div key={notification.id} className="flex items-center gap-3">
            <Switch id={notification.id} />
            <label
              htmlFor={notification.id}
              className="text-base font-medium text-navy-700 dark:text-white"
            >
              {notification.label}
            </label>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default MentorNotifications;
