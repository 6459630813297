import React, { useEffect, useRef, useState } from "react";
import Card from "components/card";
import { BiSearch } from "react-icons/bi";
import CardMenu from "components/card/CardMenu";
import SingleMessage from "./SingleMessage";
import {
  MdChevronLeft,
  MdImage,
  MdOutlineAttachment,
  MdOutlineTagFaces,
} from "react-icons/md";
import { IoPaperPlane } from "react-icons/io5";
import {
  renderThumbMessages,
  renderTrackMessages,
  renderViewMessages,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { createMessage } from "api";

import { useMutation, useQueryClient } from "@tanstack/react-query";

const SingleChat = ({ open, onClose, user, creatingNewChat, userId, userProfile }) => {
  const [newMessage, setNewMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMessages, setFilteredMessages] = useState([]);
  const scrollRef = useRef(null);

  const queryClient = useQueryClient();

  // Fetch messages from cache
  const allMessages = queryClient.getQueryData(["messages", userId]) || [];

  // Filter messages for the selected user
  const messages = allMessages
    .filter(
      (message) =>
        (message.sender.id === user.id && message.receiver.id === userProfile.id) ||
        (message.receiver.id === user.id && message.sender.id === userProfile.id)
    )
    .reverse();

  useEffect(() => {
    setFilteredMessages(messages);
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollToBottom();
    }
  };

  // Mutation for sending a new message
  const mutation = useMutation({
    mutationFn: createMessage,
    // Optimistic update
    onMutate: async (newMessageData) => {
      await queryClient.cancelQueries(["messages", userId]);

      const previousMessages = queryClient.getQueryData(["messages", userId]);

      queryClient.setQueryData(["messages", userId], (old) => {
        return old
          ? [
              ...old,
              {
                ...newMessageData,
                id: Date.now(), // Temporary ID
                timestamp: new Date().toISOString(),
                sender: { id: userId },
                receiver: { id: user.id },
              },
            ]
          : [
              {
                ...newMessageData,
                id: Date.now(), // Temporary ID
                timestamp: new Date().toISOString(),
                sender: { id: userId },
                receiver: { id: user.id },
              },
            ];
      });

      return { previousMessages };
    },
    // If mutation fails, roll back
    onError: (err, newMessageData, context) => {
      queryClient.setQueryData(["messages", userId], context.previousMessages);
    },
    // After mutation succeeds, invalidate messages query
    onSettled: () => {
      queryClient.invalidateQueries(["messages", userId]);
    },
  });

  const handleSendMessage = () => {
    if (newMessage.trim() === "" || !user) return;

    const isMentorMessagingAllowed =
      userProfile.role === "mentor" && user.schoolId === userProfile.schoolId;
    const isUserMessagingAllowed = user.teamId === userProfile.teamId;

    if (isMentorMessagingAllowed || isUserMessagingAllowed) {
      const messageData = {
        senderId: userId,
        receiverId: user.id,
        content: newMessage,
      };

      mutation.mutate(messageData);
      setNewMessage("");
      scrollToBottom();
    } else {
      console.error("You are not allowed to message this user.");
    }
  };

  const userName = `${user.firstName} ${user.lastName}`;
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const userImageUrl = user.imageUrl
    ? `${baseURL}${user.imageUrl}`
    : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

  const handleSearchMessages = () => {
    const filtered = messages.filter((msg) =>
      msg.content.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMessages(filtered);
  };
  return (
    <div
      className={`duration-175 !fixed left-0 top-[20px] !z-[50] h-[calc(100vh-40px)] w-[calc(100vw-40px)] flex-col !font-dm transition-all md:h-[calc(100vh-40px)] lg:!relative lg:left-[unset] lg:top-[unset] lg:!z-[unset] lg:my-0 lg:flex lg:h-full lg:w-[unset] ${
        open ? "translate-x-[20px] lg:translate-x-[0px]" : "-translate-x-[120%] lg:translate-x-[0px]"
      }`}
    >
      <Card extra={"w-full h-full p-4 !font-dm"}>
        {/* Header */}
        <div className="mb-auto flex flex-col lg:flex-row items-center justify-between border-b border-gray-200 pb-3 dark:border-navy-700">
          <div className="flex items-center gap-3 w-full lg:w-auto">
            <div className="flex items-center justify-center gap-2">
              <div onClick={onClose} className={"block lg:hidden"}>
                <MdChevronLeft className="h-5 w-5 text-gray-500 dark:text-white" />
              </div>
              <div className="h-[50px] w-[50px] rounded-full">
                <img
                  src={userImageUrl}
                  className="h-full w-full rounded-full object-cover"
                  alt={userName}
                />
              </div>
            </div>
            <div className="flex flex-col items-center lg:items-start">
              <h1 className="font-dm text-[18px] sm:text-[20px] font-bold text-navy-700 dark:text-white">
                {creatingNewChat ? "Create New Chat" : userName}
                {user.role === 'mentor' && (
                  <span className="ml-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white text-xs px-2 py-1 rounded-full">
                    Mentor
                  </span>
                )}
              </h1>
              {!creatingNewChat && (
                <div className="flex items-center gap-2">
                  {/* Keep circle size larger and conditionally set color */}
                  <div
                    className={`h-3 w-3 rounded-full border-2 border-white ${
                      user.status === 'Online' ? 'bg-green-500' : 'bg-red-500'
                    }`}
                  ></div>
                  <h4 className="text-base text-navy-700 dark:text-white">{user.status || "Offline"}</h4>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2 mt-3 lg:mt-0 w-full lg:w-auto">
            <input
              type="text"
              placeholder="Search messages..."
              className="block h-10 w-full lg:w-40 px-4 rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-gray-100 dark:py-2 dark:px-4"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <BiSearch className="h-5 w-5 text-dark dark:text-white hover:cursor-pointer" onClick={handleSearchMessages} />
            <CardMenu vertical />
          </div>
        </div>
        {/* Message Content */}
        <div className="relative h-[calc(100%-70px)] pt-2 overflow-y-auto scrollbar-hide">
          <div className="flex h-[calc(100%-74px)] w-full">
            <Scrollbars
              renderTrackVertical={renderTrackMessages}
              renderThumbVertical={renderThumbMessages}
              renderView={renderViewMessages}
              ref={scrollRef}
            >
              <div className="relative max-h-max overflow-hidden pb-[30px] lg:max-h-[unset] lg:overflow-visible">
                {filteredMessages.map((msg, index) => (
                  <div key={index} className={`flex ${msg.sender.id === userId ? 'justify-end' : 'justify-start'}`}>
                    <SingleMessage
                      message={msg.content}
                      time={msg.timestamp}
                      isSent={msg.sender.id === userId}
                    />
                  </div>
                ))}
              </div>
            </Scrollbars>
          </div>
          {/* Message Input */}
          <div className="mt-6 flex items-center gap-2">
            <div className="flex h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3 dark:bg-navy-700">
              <p className="text-lightSecondary pl-3 text-xl hover:cursor-pointer">
                <MdOutlineTagFaces />
              </p>
              <input
                placeholder="Write your message..."
                className="h-full w-full rounded-full bg-lightPrimary px-2 text-sm text-navy-700 outline-none dark:bg-navy-700 dark:text-white"
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSendMessage(); }}
              />
              <div className="flex items-center gap-4">
                <p className="text-lightSecondary text-xl hover:cursor-pointer">
                  <MdOutlineAttachment />
                </p>
                <p className="text-lightSecondary pr-2 text-xl hover:cursor-pointer">
                  <MdImage />
                </p>
              </div>
            </div>
            <button
              className="linear flex items-center justify-center rounded-full bg-brand-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleSendMessage}
            >
              <IoPaperPlane />
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SingleChat;
