import Card from "components/card";
import TimelineItem from "components/dataDisplay/TimelineItem";
import { fetchTimelines, getUserProfile } from "api";
import { useEffect, useState } from "react";

const TimeLine = () => {
  const [timelines, setTimelines] = useState([]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userProfile = await getUserProfile();
        const schoolName = userProfile.user?.school || "";
        loadTimelines(schoolName);
      } catch (error) {
        console.error("Error loading user profile:", error);
        loadTimelines(null);
      }
    };

    loadUserData();
  }, []);

  const loadTimelines = async (schoolName) => {
    try {
      const timelinesData = await fetchTimelines(schoolName);

      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const diff = currentDay === 0 ? -6 : 1 - currentDay;
      const monday = new Date(currentDate);
      monday.setDate(currentDate.getDate() + diff);

      const weekDays = Array.from({ length: 5 }).map((_, i) => {
        const date = new Date(monday);
        date.setDate(monday.getDate() + i);
        return date;
      });

      const placeholders = [
        { weekday: 'Monday', date: weekDays[0] },
        { weekday: 'Tuesday', date: weekDays[1] },
        { weekday: 'Wednesday', date: weekDays[2] },
        { weekday: 'Thursday', date: weekDays[3] },
        { weekday: 'Friday', date: weekDays[4] }
      ];

      const filteredTimelines = placeholders.map((placeholder) => {
        const matchedTimeline = timelinesData.find(timeline => {
          const startDate = new Date(timeline.startDate);
          return startDate.getDay() === placeholder.date.getDay();
        });

        if (matchedTimeline) {
          return {
            title: matchedTimeline.title,
            day: placeholder.date.getDate(),
            weekday: placeholder.weekday,
            hours: matchedTimeline.hours,
            current: matchedTimeline.current ? "current" : "",
            mb: "mb-[16px]"
          };
        } else {
          return {
            title: `No events scheduled`,
            day: placeholder.date.getDate(),
            weekday: placeholder.weekday,
            hours: "",
            current: "",
            mb: "mb-[16px]"
          };
        }
      });

      setTimelines(filteredTimelines);
    } catch (error) {
      console.error("Error fetching timelines:", error);
    }
  };

  return (
    <Card extra={"w-full p-4 md:p-6 lg:p-8"}>
      {/* Header */}
      <div>
        <h4 className="text-lg md:text-xl font-bold text-navy-700 dark:text-white">
          Timeline
        </h4>
        <p className="text-sm md:text-base font-medium text-gray-600">
          Review your upcoming activities
        </p>
      </div>

      {/* Timeline items */}
      <div className="mt-[20px] md:mt-[30px]">
        {timelines.map((timeline, index) => (
          <TimelineItem
            key={index}
            title={timeline.title}
            day={timeline.day}
            weekday={timeline.weekday}
            hours={timeline.hours}
            current={timeline.current}
            mb={timeline.mb}
          />
        ))}
      </div>
    </Card>
  );
};

export default TimeLine;
