import NftCard from "components/card/NftCard";
import Banner from "./components/Banner";
import Search from "./components/Search";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import print from "../../../../assets/img/courses/3D Printing Course.jpg";
import robotics from "../../../../assets/img/courses/Robotics.jpg";
import arduino from "../../../../assets/img/courses/Arduino Course.jpg";
import ai from "../../../../assets/img/courses/AI Course.jpg";
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import { fetchAllCourses, getUserProfile, fetchCoursesByUserId, fetchCoursesSubStatusByUserId } from "api";
import { useEffect, useState } from "react";
import Loader from "components/loaders/Loader";


const NftCollection = () => {
  const [ courses, setCourses ] = useState([]);
  const [ courseStatuses, setCourseStatuses] = useState({});
  const [ loading, setLoading ] = useState(true);
  const [ userId, setUserId ] = useState(null);
  const [ mentor, setMentor ] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sortOrder, setSortOrder] = useState("popularity");

  const fetchStatuses = async (userId) => {
    const coursesWithStatus = await fetchCoursesSubStatusByUserId(userId);
    const statuses = coursesWithStatus.reduce((acc, course) => {
      acc[course.id] = course.status;
      return acc;
    }, {});
    setCourseStatuses(statuses);
  };

  useEffect(() => {
    const initializeData = async () => {
      try {
        const profile = await getUserProfile();
        setUserId(profile.user.id);
        setMentor(profile.user.mentor);
        const fetchedCourses = await fetchAllCourses();
        setCourses(fetchedCourses);
        await fetchStatuses(profile.user.id);
      } catch (error) {
        console.error("Error initializing data:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, []);

  const handleStatusUpdate = () => {
    if (userId) {
      fetchStatuses(userId); 
    }
  };

  const getCourseImage = (title) => {
    switch (title) {
      case "3D Printing":
        return print;
      case "Robotics":
        return robotics;
      case "Arduino":
        return arduino;
      case "Artificial Intelligence":
        return ai;
      default:
        return "defaultImage.png"; 
    }
  };

    // Handler functions for search and filters
    const handleSearchChange = (query) => {
      setSearchQuery(query);
    };

    const handleCategoryChange = (category) => {
      setSelectedCategory(category);
    };

    const handleSortChange = (sortOption) => {
      setSortOrder(sortOption);
    };

     // Apply filtering and sorting
  const filteredCourses = courses.filter((course) => {
    // Filter by search query
    const matchesSearch = course.title.toLowerCase().includes(searchQuery.toLowerCase());

    // Filter by category
    const matchesCategory =
      selectedCategory === "all" || course.title === selectedCategory;

    return matchesSearch && matchesCategory;
  });

  // Sorting logic
  const sortedCourses = filteredCourses.sort((a, b) => {
    switch (sortOrder) {
      case "price-low-high":
        return (a.price || 0) - (b.price || 0);
      case "price-high-low":
        return (b.price || 0) - (a.price || 0);
      case "duration":
        return (a.duration || 0) - (b.duration || 0);
      case "popularity":
      default:
        // Assuming you have a 'popularity' or 'enrollments' field
        return (b.popularity || 0) - (a.popularity || 0);
    }
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mt-3 h-full w-full rounded-[20px]">
      <Banner />
      <div className="mt-[70px] w-full">
      <Search
        onSearchChange={handleSearchChange}
        onCategoryChange={handleCategoryChange}
        onSortChange={handleSortChange}
      />
      </div>

      <h4 className="ml-[23px] mt-8 text-2xl font-bold text-navy-700 dark:text-white">
        Current Courses
      </h4>

      <div className="mt-[38px] grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4">
      {sortedCourses.map((course) => {
          const isAvailable = course.title === "3D Printing"; // Adjust this condition as needed

          return (
            <NftCard
              key={course.id}
              students={[avatar1, avatar2, avatar3]}
              title={course.title}
              author={mentor ? `${mentor.firstName} ${mentor.lastName}` : "Unknown Mentor"}
              price={course.price ? `$${course.price}` : "Free"}
              image={getCourseImage(course.title)}
              courseId={course.id}
              userId={userId}
              mentorId={mentor?.id}
              status={courseStatuses[course.id] || "available"}
              onStatusUpdate={handleStatusUpdate}
              isAvailable={isAvailable}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NftCollection;
