import React, { useEffect, useState } from "react";
import NftLarge1 from "assets/img/nfts/nairobi.jpg";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { subscribeToNewsletter } from "api";
import Loader from "components/loaders/Loader";


const NftPage = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = NftLarge1;
    image.onload = () => setIsImageLoaded(true);
  }, []);

  const handleSubscribe = async (event) => {
    event.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    try {
      await subscribeToNewsletter(email);
      setSuccessMessage("Subscribed successfully!");
      setEmail("");
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Subscription failed");
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  if (!isImageLoaded) {
    return <Loader />;
  }

  return (
    <div
      className="w-full h-screen bg-cover bg-center"
      style={{
        backgroundImage: `url(${NftLarge1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="w-full h-screen flex flex-col items-center justify-between bg-black bg-opacity-70 py-8 px-4 sm:px-8 lg:px-16">
        <div className="flex-1 flex flex-col items-center justify-center text-center">
          <div className="bg-white bg-opacity-10 px-4 py-2 rounded-xl flex items-center justify-center text-orange-100 space-x-2 lg:space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 lg:h-8 xl:h-10 w-6 lg:w-8 xl:w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
              />
            </svg>
            <span className="text-xl lg:text-2xl xl:text-3xl font-bold">
              3D Printing Competition
            </span>
          </div>
          <h1 className="text-4xl sm:text-6xl lg:text-7xl xl:text-8xl text-orange-200 tracking-wider font-bold font-serif mt-12">
            Coming Soon
          </h1>
          <div className="flex flex-col items-center space-y-4 mt-12 sm:mt-24">
            <p className="text-gray-300 uppercase text-sm">
              Notify me when it's ready
            </p>
            <form className="w-full max-w-md flex items-center" onSubmit={handleSubscribe}>
              <input
                type="email"
                name="email"
                id="email"
                className="flex-1 p-2 border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-300 rounded-tl rounded-bl text-sm"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                type="submit"
                className={`py-2 px-6 text-gray-100 border rounded-tr rounded-br text-sm ${
                  isValidEmail(email)
                    ? "bg-orange-600 border-orange-600 hover:bg-orange-700"
                    : "bg-gray-400 border-gray-400 cursor-not-allowed"
                }`}
                disabled={!isValidEmail(email)}
              >
                Subscribe
              </button>
            </form>
            {successMessage && (
              <p className="text-green-500 mt-2">{successMessage}</p>
            )}
            {errorMessage && (
              <p className="text-red-500 mt-2">{errorMessage}</p>
            )}
          </div>
        </div>
        <div className="w-full flex items-center justify-center">
          <ul className="flex items-center space-x-4">
            <li>
              <a
                href="https://www.facebook.com/InnovatorsForge"
                title="Facebook"
                className="text-orange-200 hover:text-white transition duration-300"
              >
                <FaFacebookF className="w-6 lg:w-8 h-6 lg:h-8 hover:scale-110 transition-transform" />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/innovatorsforge"
                title="X"
                className="text-orange-200 hover:text-white transition duration-300"
              >
                <FaSquareXTwitter className="w-6 lg:w-8 h-6 lg:h-8 hover:scale-110 transition-transform" />
              </a>
            </li>
            <li>
              <a
                href="#"
                title="Instagram"
                className="text-orange-200 hover:text-white transition duration-300"
              >
                <FaInstagram className="w-6 lg:w-8 h-6 lg:h-8 hover:scale-110 transition-transform" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NftPage;
