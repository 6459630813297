import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { forgotPassword } from "api"; 


function ForgotPasswordDefault() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Email validation regex pattern
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if the email is empty or invalid before submitting
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await forgotPassword(email);
      setMessage(response.message);
      setError("");
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      setMessage("");
    }
  };

  // Clear error and message when email input changes
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");  // Clear any existing error message
    setMessage(""); // Clear any existing success message
  };

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          <div className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[42%] xl:pr-24">
            <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
              Forgot Your Password?
            </h2>
            <p className="mt-[10px] ml-1 mb-8 text-base text-gray-600 dark:text-gray-300">
              No problem. Just let us know your email address and we'll email
              you a password reset link that will allow you to choose a new one.
            </p>
            {message && <p className="text-green-500">{message}</p>}
            {error && <p className="text-red-500">{error}</p>}
            <InputField
              variant="auth"
              label="Email"
              placeholder="mail@simmmple.com"
              id="email"
              type="email"
              value={email}
              onChange={handleEmailChange} // Use handleEmailChange to clear messages on input change
            />
            <button
              className={`mt-4 w-full rounded-xl py-3 text-base font-medium text-white transition duration-200 ${
                email && emailRegex.test(email)
                  ? 'bg-orange-400 hover:bg-brand-900 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200'
                  : 'bg-gray-300 cursor-not-allowed'
              }`}
              onClick={handleSubmit}
              disabled={!email || !emailRegex.test(email)} // Disable button if email field is empty or invalid
            >
              Email password reset link
            </button>
          </div>
        </div>
      }
    />
  );
}

export default ForgotPasswordDefault;
