import React, { useEffect, useState } from "react";
import { MdOutlineHomeWork, MdOutlineWorkOutline, MdOutlineGroupAdd, MdPrint, MdOutlineMoveToInbox, MdDesignServices } from "react-icons/md";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Transaction from "components/dataDisplay/Transaction";
import { fetchPoapDescriptions, getUserProfile, fetchCurrentDescription } from "api";
import { useNavigate } from "react-router-dom";
import { FaCube, FaPrint } from "react-icons/fa";

function Balance() {

  const navigate = useNavigate();
  const [poapDescriptions, setPoapDescriptions] = useState([]);
  const [currentTopic, setCurrentTopic] = useState('Loading...');
  const [error, setError] = useState(null);


  useEffect(() => {
  const loadPoapDescriptions = async () => {
    try {
      const profile = await getUserProfile();
      const teamId = profile.user.team?.id;
      if (teamId) {
        // Fetch current description ID and project name
        const { descriptionId, projectName } = await fetchCurrentDescription(teamId);
        console.log("Fetched Current Description:", { descriptionId, projectName });
        setCurrentTopic(projectName);
      }
      const descriptions = await fetchPoapDescriptions();
      setPoapDescriptions(descriptions);
    } catch (error) {
      console.error("Error fetching data", error);
      setError('Failed to load data');
    }
  };

  loadPoapDescriptions();
}, []);

  const handleViewClick = (projectName) => { // Added handleViewClick function
    const selectedPoap = poapDescriptions.find(poap => poap.projectName === projectName);
    if (selectedPoap) {
      navigate('/admin/poaps-desc', {
        state: { poap: selectedPoap }
      });
    }
  };



  return (
    <Card extra={"p-4 h-full"}>
      <div
        className={`bg-image relative mt-[7px] flex h-fit w-full justify-between rounded-xl bg-brand-900 bg-balanceDashboard bg-cover bg-right px-3 py-4 text-white dark:bg-navy-700`}
        bgSize="cover"
      >
        <div className="flex flex-col w-full">
          <p className="text-[24px] !font-bold">Projects</p>
          <div className="flex justify-between items-center">
            <h5 className="text-[14px] font-medium">Current Project</h5>
            <div className="text-lg text-cream-300">
              {currentTopic}
            </div>
          </div>
        </div>
      </div>
      <p className="mt-[19px] text-sm font-medium text-gray-600">Recent Projects</p>

      {/* Topics section */}
      <div className="mt-[1px]">
        <Transaction
          title="Team Formation"
          sum={
            <button 
            className="rounded-xl border-2 border-orange-500 px-1.5 py-0.5 text-base font-medium text-orange-500 transition duration-200 hover:bg-orange-500 hover:text-white active:bg-orange-700/5 dark:border-orange-400 dark:bg-orange-400/10 dark:text-white dark:hover:bg-orange-500 dark:active:bg-orange-200/10"
            onClick={() => handleViewClick("Team Formation")}
            >
              View
            </button>
          }
          mb="mb-[20px]"
          icon={
            <MdOutlineGroupAdd className="text-brand-500 dark:text-white" />
          }
        />

        <Transaction
          title="3D Design"
          
          sum={
            <button className="rounded-xl border-2 border-orange-500 px-1.5 py-0.5 text-base font-medium text-orange-500 transition duration-200 hover:bg-orange-500 hover:text-white active:bg-orange-700/5 dark:border-orange-400 dark:bg-orange-400/10 dark:text-white dark:hover:bg-orange-500 dark:active:bg-orange-200/10"
            onClick={() => handleViewClick("3D Design")}
            >
              View
            </button>
          }
          mb="mb-[20px]"
          icon={<MdDesignServices className="text-green-500 dark:text-white" />}
        />

        <Transaction
          title="3D Printers"
         
          sum={
            <button className="rounded-xl border-2 border-orange-500 px-1.5 py-0.5 text-base font-medium text-orange-500 transition duration-200 hover:bg-orange-500 hover:text-white active:bg-orange-700/5 dark:border-orange-400 dark:bg-orange-400/10 dark:text-white dark:hover:bg-orange-500 dark:active:bg-orange-200/10" 
            onClick={() => handleViewClick("3D Printers")}
            >
              View
            </button>
          }
          mb="mb-[20px]"
          icon={<MdOutlineMoveToInbox className="text-yellow-500 dark:text-white" />}
        />

        <Transaction
          title="CAD Design"
          
          sum={
            <button className="rounded-xl border-2 border-orange-500 px-1.5 py-0.5 text-base font-medium text-orange-500 transition duration-200 hover:bg-orange-500 hover:text-white active:bg-orange-700/5 dark:border-orange-400 dark:bg-orange-400/10 dark:text-white dark:hover:bg-orange-500 dark:active:bg-orange-200/10"
            onClick={() => handleViewClick("Custom 3D Designs with CAD")}
            >
              View
            </button>
          }
          mb="mb-[5px]"
          icon={<MdOutlineAssignmentTurnedIn className="text-yellow-500 dark:text-white" />}
        />
        
      </div>
    </Card>
  );
}

export default Balance;
