import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { MdDelete } from "react-icons/md";
import { updateUser, deleteUser } from "api";
import InputField from "components/fields/InputField";
import Dropdown from "components/dropdown";

const EditUserModal = ({ user, onClose }) => {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [school, setSchool] = useState(user.school);
  const [enrollYear, setEnrollYear] = useState(user.enrollYear);
  const [status, setStatus] = useState(user.status);
  const [role, setRole] = useState(user.role || "user"); // Default role to 'user'

  const handleSave = async () => {
    try {
      const updatedUser = {
        ...user,
        firstName,
        lastName,
        email,
        school,
        enrollYear,
        status,
        role,
      };
      await updateUser(user.id, updatedUser);
      console.log("User updated successfully:", updatedUser);
      onClose();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        await deleteUser(user.id);
        console.log("User deleted successfully:", user);
        onClose();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <div
        className="p-6 bg-white rounded-lg shadow-md dark:bg-navy-800 max-h-[80vh] overflow-y-auto"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(222, 222, 222, 0.2) transparent",
        }}
      >
        <style>
          {`
            .custom-scrollbar::-webkit-scrollbar {
              width: 8px;
            }
            .custom-scrollbar::-webkit-scrollbar-track {
              background: transparent;
            }
            .custom-scrollbar::-webkit-scrollbar-thumb {
              background-color: rgba(222, 222, 222, 0.2);
              border-radius: 6px;
              transition: background-color 0.2s ease-in-out;
            }
            .custom-scrollbar::-webkit-scrollbar-thumb:hover {
              background-color: rgba(222, 222, 222, 0.4);
            }
            .dark .custom-scrollbar::-webkit-scrollbar-thumb {
              background-color: rgba(74, 85, 104, 0.5);
            }
            .dark .custom-scrollbar::-webkit-scrollbar-thumb:hover {
              background-color: rgba(74, 85, 104, 0.7);
            }
          `}
        </style>
        <h3 className="text-xl font-bold text-navy-700 dark:text-white mb-4">
          Edit User
        </h3>
        <div className="space-y-4">
          <InputField
            type="text"
            label="First Name"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <InputField
            type="text"
            label="Last Name"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <InputField
            type="email"
            label="Email"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputField
            type="text"
            label="School"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white"
            value={school}
            onChange={(e) => setSchool(e.target.value)}
          />
          <InputField
            type="text"
            label="Enrollment Year"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white"
            value={enrollYear}
            onChange={(e) => setEnrollYear(e.target.value)}
          />
          <Dropdown
            label="Status"
            variant="manage"
            button={
              <button className="py-2 px-4 w-full text-left rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white">
                {status}
              </button>
            }
            classNames="bg-white shadow-lg rounded-md mt-1"
          >
            {["Online", "Offline", "Busy", "Away"].map((option) => (
              <button
                key={option}
                onClick={() => setStatus(option)}
                className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-navy-700"
              >
                {option}
              </button>
            ))}
          </Dropdown>
          <Dropdown
            label="Role"
            variant="manage"
            button={
              <button className="py-2 px-4 w-full text-left rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white">
                {role}
              </button>
            }
            classNames="bg-white shadow-lg rounded-md mt-1"
          >
            {["user", "admin"].map((option) => (
              <button
                key={option}
                onClick={() => setRole(option)}
                className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-navy-700"
              >
                {option}
              </button>
            ))}
          </Dropdown>
        </div>
        <div className="mt-6 flex justify-between items-center">
          <button
            className="flex flex-row items-center rounded-xl bg-red-500 px-4 py-3 text-sm font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
            onClick={handleDelete}
          >
            <MdDelete className="mr-2" />
            Delete
          </button>
          <div className="flex space-x-4">
            <button
              className="rounded-lg bg-gray-200 py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 transition duration-200"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="flex flex-row items-center rounded-xl bg-orange-500 px-4 py-3 text-sm font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-orange-400 dark:text-white dark:hover:bg-orange-300 dark:active:bg-orange-200"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserModal;
