import React, { useState, useEffect } from "react";
import Dropdown from "components/dropdown";
import { IoChatboxEllipses } from "react-icons/io5";
import { fetchUnreadMessages, markMessagesAsRead, getUserProfile } from "api";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const MessageDropdown = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [error, setError] = useState(null);

  const {
    data: userProfileData,
    isLoading: userLoading,
    error: userError
  } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
  });

  const userProfile = userProfileData?.user;

  //Fetch unread messages
  const {
    data: unreadMessagesData,
    isLoading: messagesLoading,
    error: messagesError,
  } = useQuery({
    queryKey: ['unreadMessages', userProfile?.id],
    queryFn: () => fetchUnreadMessages(userProfile.id),
    enabled: !!userProfile,
    onError: (err) => {
      console.error('Error fetching unread messages:', err);
      setError('Failed to load messages');
    } 
  });

  const messages = unreadMessagesData || [];

  // Mutation to mark message as read
  const markAsReadMutation = useMutation({
    mutationFn:(messageIds) => markMessagesAsRead(messageIds),
    onSuccess: () => {
      queryClient.invalidateQueries(['unreadMessages', userProfile?.id]);
    },
    onError: (err) => {
      console.error('Error marking messages as read:', err);
      setError('Failed to mark message as read.');
    }
  });

  const handleMessageClick = async (message) => {
    try {
      await markAsReadMutation.mutateAsync([message.id]);
      navigate(`/admin/inbox?chatWith=${message.sender.id}`)
    } catch (error) {
      // Error is handled in onError callback
    }
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();
    return isToday
      ? date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : date.toLocaleDateString();
  };

  const handleViewAllMessages = () => {
    navigate("/admin/inbox");
  };

  const hasNewMessages = messages.length > 0;

  if (userLoading || messagesLoading) {
    return (
      <div className="relative cursor-pointer">
        <IoChatboxEllipses className="h-5 w-5 text-gray-600 dark:text-white" />
      </div>
    )
  };

  if (userError || messagesError) {
    console.error('Error loading data:', userError || messagesError)
  };


  return (
    <Dropdown
      button={
        <div className="relative cursor-pointer">
          <IoChatboxEllipses
            className={`${
              hasNewMessages ? "h-6 w-6 text-green-500" : "h-5 w-5 text-gray-600 dark:text-white"
            }`}
          />
          {hasNewMessages && (
            <span className="absolute -top-1 -right-1 flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            </span>
          )}
        </div>
      }
      children={
        <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
          <p className="text-lg font-bold text-navy-700 dark:text-white">Messages</p>
          {error && <p className="text-sm text-red-500">{error}</p>}
            <div className="flex flex-col gap-3">
              {messages.length > 0 ? (
                messages.map((message, index) => {
                  const imageUrl = message.sender.imageUrl
                    ? `${process.env.REACT_APP_API_BASE_URL}${message.sender.imageUrl}` // Append base URL if necessary
                    : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"; // Fallback image

                  return (
                    <div
                      key={index}
                      className="flex items-start gap-3 cursor-pointer"
                      onClick={() => handleMessageClick(message)}
                    >
                      <img
                        src={imageUrl}
                        alt={`${message.sender.firstName} ${message.sender.lastName}`}
                        className="h-10 w-10 rounded-full object-cover"
                      />
                      <div className="flex-1">
                        <p className="text-sm font-semibold text-navy-700 dark:text-white">
                          {message.sender.firstName} {message.sender.lastName}
                        </p>
                        <p className="text-xs text-gray-600 dark:text-gray-300">{message.content}</p>
                        <p className="text-xs text-gray-400 dark:text-gray-500">
                          {formatTime(message.timestamp)}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-sm text-gray-600 dark:text-gray-300">No new messages</p>
              )}
            </div>
          <button
            onClick={handleViewAllMessages}
            className="mt-4 block w-full text-center text-sm font-bold text-brand-500 hover:text-brand-600 dark:text-brand-400 dark:hover:text-brand-300"
          >
            View All Messages
          </button>
        </div>
      }
      classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
      animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
    />
  );
};

export default MessageDropdown;
