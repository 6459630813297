import React, { useState, useEffect } from "react";
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { getUserProfile } from "api";
import md5 from "md5";
import { FiCamera } from "react-icons/fi";
import ErrorAlerts from "components/alerts/ErrorAlerts";
import SuccessAlert from "components/alerts/SuccessAlerts";

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const MentorBanner = ( {onChangeProfilePicture} ) => {
  const [user, setUser] = useState({
    name: '',
    role: 'iForge Mentor',
    imageUrl: avatar,
  });

  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userProfile = await getUserProfile();
        const email = userProfile?.user?.email || '';
        

        const imageUrl = userProfile?.user?.imageUrl 
          ? `${process.env.REACT_APP_API_BASE_URL}${userProfile.user.imageUrl}`
          : getGravatarUrl(email);

        setUser({
          name: `${userProfile.user.firstName} ${userProfile.user.lastName}`,
          role: 'iForge Mentor',
          imageUrl,
        });

      } catch (error) {
        console.error("Error fetching user data:", error);
        setUser((prevUser) => ({
          ...prevUser,
          imageUrl: avatar,
        }));
      }
    };

    fetchUserData();
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessages(["File is too large. Please upload a file smaller than 5MB"]);
        return;        
      }
      setErrorMessages([]);

      try {
        await onChangeProfilePicture(file);
        setSuccessMessage("Profile picture updated successfully!");
      } catch (error) {
        console.error("Error uploading profile picture", error);
        setErrorMessages(["Failed to upload profile picture. Please try again."]);        
      }
    }
  };

  return (
    <div>
      {errorMessages.length > 0 && (
        <ErrorAlerts errors={errorMessages} onClose={() => setErrorMessages([])} />
      )}

      {successMessage && (
        <SuccessAlert message={successMessage} onClose={() => setSuccessMessage(null)} />
      )}
      
    <Card extra="items-center w-full h-full p-4 bg-cover">
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full object-cover" src={user.imageUrl} alt="Profile picture" />
           
           {/* Edit Icon Overlay */}
           <div className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity cursor-pointer">
            <label htmlFor="mentorFileInput" className="flex items-center justify-center text-white cursor-pointer">
              <FiCamera size={24} />
            </label>
            <input
              id="mentorFileInput"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden"
            />
          </div>

        </div>
      </div>

      <div className="mt-16 flex flex-col items-center text-center">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white md:text-xl">
          {user.name}
        </h4>
        <h5 className="text-sm font-normal text-gray-600 dark:text-gray-300 md:text-base">{user.role}</h5>
      </div>
    </Card>
    </div>
  );
};

export default MentorBanner;
