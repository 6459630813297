import Card from "components/card";
import { useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Instructor from "./Instructor";

const CourseInfo = ({ description, totalPoints, mentor }) => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const renderContent = () => {
    if (toggleState === 1) {
      return (
        <div className="mt-10">
          <p className="pr-4 text-lg text-gray-700 dark:text-white mb-10 leading-6">
            {description}
          </p>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div>
              <p className="text-[74px] font-bold text-yellow-500">{totalPoints}</p>
              <p className="text-base font-bold text-gray-800">Total Points</p>
            </div>
          </div>
        </div>
      );
    } else if (toggleState === 2) {
      return (
        <div className="mt-10">
          <p className="text-lg font-medium text-navy-700 dark:text-white mb-4">Resources</p>
          <ul className="list-disc pl-5 space-y-2 text-gray-700 dark:text-white">
            <li>
              <a href="https://example.com/resource1" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">PrintLab</a>
            </li>
            <li>
              <a href="https://example.com/resource2" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Thingiverse</a>
            </li>
            <li>
              <a href="https://example.com/resource3" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Tinkercad</a>
            </li>
          </ul>
        </div>
      );
    }
  };

  return (
    <Card extra={"w-full !py-6 !pb-16 !px-4 md:!px-10"}>
      {/* Header with navigation arrows */}
      <div className="mb-2 mt-3 flex items-center justify-end">
        <p className="text-base font-medium uppercase text-gray-600 dark:text-white">
          Section {toggleState}/2
        </p>
        <div className="flex items-center gap-2 ml-3">
          <button
            className="flex items-center justify-center rounded-full border-2 border-gray-600 p-1.5 text-3xl text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:border-white dark:bg-none dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            onClick={() => toggleTab(toggleState - 1)}
            disabled={toggleState === 1}
          >
            <MdChevronLeft />
          </button>
          <button
            className="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={() => toggleTab(toggleState + 1)}
            disabled={toggleState === 2}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>

      {/* Navigation tabs */}
      <div className="mb-1 flex w-full flex-col items-center rounded-xl xl:mb-3">
        <div className="flex w-full justify-start gap-8 overflow-hidden text-3xl">
          {['Notes', 'Resources'].map((tab, index) => (
            <div
              key={tab}
              className={`flex items-center gap-3 border-b-[4px] ${
                toggleState === index + 1 ? 'border-brand-500 dark:border-brand-400' : 'border-white dark:border-navy-700'
              } pb-3 hover:cursor-pointer`}
              onClick={() => toggleTab(index + 1)}
            >
              <p className="text-[18px] font-medium text-navy-700 dark:text-white">
                {tab}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Main content */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {renderContent()}
        <Instructor mentor={mentor}/>
      </div>
    </Card>
  );
};

export default CourseInfo;
