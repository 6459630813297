import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import Card from "components/card";
import Transfer from "components/dataDisplay/Transfer";
import { fetchTeamMembers, getUserProfile } from "api";
import { useNavigate } from "react-router-dom";

function YourTransfers() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchProfileAndMembers = async () => {
      try {
        const profile = await getUserProfile();
        if (profile && profile.user) {
          setUserProfile(profile.user);
          
          // Ensure the teamId is available and fetch team members
          if (profile.user.team?.id) {
            const members = await fetchTeamMembers(profile.user.team.id);
            
            // Filter out the current user from the team members
            const filteredMembers = members.filter(member => member.id !== profile.user.id);
            setTeamMembers(filteredMembers);
          } else {
            console.warn("User is not part of any team.");
          }
        } else {
          console.warn("Failed to fetch user profile.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProfileAndMembers();
  }, []);

  const handleChatClick = (member) => {
    navigate(`/admin/inbox?chatWith=${member.id}`);
  };

  return (
    <Card extra={"!p-5 h-full"}>
      <h4 className="mb-6 text-lg font-bold text-navy-700 dark:text-white">
        Team Members
      </h4>
      {teamMembers.length > 0 ? (
        teamMembers.map((member) => (
          <Transfer
            key={member.id}
            name={`${member.firstName} ${member.lastName}`} // Display full name
            date="Today"
            sum={
              <button
                className="rounded-xl bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] px-2 py-1 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FD1D1D]/50"
                onClick={() => handleChatClick(member)}
              >
                chat
              </button>
            }
            avatar={
              member.imageUrl
                ? `${baseURL}${member.imageUrl}` // Construct the full URL for the avatar
                : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y' // Fallback to default Gravatar image
            }
          />
        ))
      ) : (
        <div className="flex flex-col items-center justify-center h-full text-center text-navy-700 dark:text-white">
          <p className="text-lg font-semibold">You are not part of a team yet.</p>
          <p className="text-base mt-2">Please join a team to see your team members here.</p>
        </div>
      )}
      <div className="mb-auto" />
      {teamMembers.length > 0 && (
        <div className="flex w-full items-center justify-end gap-1 hover:cursor-pointer mt-4">
          <div className="text-sm font-bold text-brand-500 transition-all hover:-translate-x-1 dark:text-white">
            View More
          </div>
          <div className="text-xl font-bold text-brand-500 transition-all hover:translate-x-1 dark:text-white">
            <BsArrowRight />
          </div>
        </div>
      )}
    </Card>
  );
}

export default YourTransfers;
