import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Card from 'components/card';
import Modal from 'components/modal/Modal';
import { FaCheckCircle, FaChevronDown, FaChevronRight, FaPlusCircle, FaSearch } from 'react-icons/fa';
import { completeStep, getUserProfile, fetchTeamsByMentor, fetchTeams, fetchPoapDescriptions, fetchTeamStepStatus } from 'api';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Loader from 'components/loaders/Loader';

const ManageTeam = () => {
  const location = useLocation();
  const { selectedTeam, selectedPoap, selectedStepId, categoryIndex, stepIndex } = location.state || {};

  const [selectedSubject, setSelectedSubject] = useState('3D Printing'); // Preset the subject to "3D Printing"
  const [expandedTeam, setExpandedTeam] = useState(null);
  const [expandedPoap, setExpandedPoap] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState({ teamId: selectedTeam, poapId: selectedPoap, categoryIndex, stepIndex });
  const [searchQuery, setSearchQuery] = useState('');

  const queryClient = useQueryClient();

  //Fetch user profile
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError
  } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile
  });

  const user = profileData?.user;
  const mentorId = user?.role === 'mentor' ? user.id : user?.mentor?.id;


  // Fetch teams assigned to the mentor
  const {
    data: teamsData,
    isLoading: teamsLoading,
    error: teamsError,
  } = useQuery({
    queryKey: ['teamsByMentor', mentorId],
    queryFn: () => fetchTeamsByMentor(mentorId),
    enabled: !!mentorId,
  });


  // Fetch POAP Descriptions
  const {
    data: poapsData,
    isLoading: poapsLoading,
    error: poapsError
  } = useQuery({
    queryKey: ['poapDescriptions'],
    queryFn: fetchPoapDescriptions,
  });


  // Fetch team step status
  const { data: teamStepStatusesData, isLoading: stepStatusesLoading, error: stepStatusesError } = useQuery({
    queryKey: ['teamStepStatuses', expandedTeam, expandedPoap],
    queryFn: async () => {
      const descriptionId = poapsData?.find(poap => poap.id === expandedPoap)?.id;
      if (!descriptionId) return [];
      return await fetchTeamStepStatus(expandedTeam, descriptionId);
    },
    enabled: !!(expandedTeam && expandedPoap && poapsData),
  });

  useEffect(() => {
    if (
      !modalVisible &&
      selectedTeam &&
      selectedPoap &&
      categoryIndex !== undefined &&
      stepIndex !== undefined &&
      !teamsLoading &&
      !poapsLoading &&
      !stepStatusesLoading &&
      teamsData &&
      poapsData
    ) {
      // Expand the team and POAP
      setExpandedTeam(selectedTeam);
      setExpandedPoap(selectedPoap);
      // Set the current step for the modal
      setCurrentStep({
        teamId: selectedTeam,
        poapId: selectedPoap,
        categoryIndex,
        stepIndex,
      });
      // Open the modal
      setModalVisible(true);
    }
  }, [
    modalVisible,
    selectedTeam,
    selectedPoap,
    categoryIndex,
    stepIndex,
    teamsLoading,
    poapsLoading,
    stepStatusesLoading,
    teamsData,
    poapsData,
  ]);

  // Derive assignedSteps from teamStepStatusesData
  const assignedSteps = useMemo(() => {
    if (!teamStepStatusesData) return {};
    return teamStepStatusesData.reduce((acc, stepStatus) => {
      const key = `${expandedTeam}-${expandedPoap}-${stepStatus.poapStepId}`;
      acc[key] = stepStatus.status === 'completed';
      return acc;
    }, {});
  }, [teamStepStatusesData, expandedTeam, expandedPoap]);
  

  //Mutation for completing a step
  const completeStepMutation = useMutation({
    mutationFn: ({ teamId, stepId, descriptionId }) => completeStep(teamId, stepId, descriptionId),
    onMutate: async ({ teamId, stepId }) => {
      await queryClient.cancelQueries(['teamStepStatuses', expandedTeam, expandedPoap]);

      const previousData = queryClient.getQueryData(['teamStepStatuses', expandedTeam, expandedPoap]);

      queryClient.setQueryData(['teamStepStatuses', expandedTeam, expandedPoap], (oldData) =>
        oldData.map((stepStatus) =>
          stepStatus.poapStepId === stepId ? { ...stepStatus, status: 'completed' } : stepStatus
        )
      );

      return { previousData };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['teamStepStatuses', expandedTeam, expandedPoap], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['teamStepStatuses', expandedTeam, expandedPoap]);
    },
  });



  const handleAssignPoints = async () => {
    try {
      const { teamId, poapId, categoryIndex, stepIndex } = currentStep;
      const poap = poapsData?.find((poap) => poap.id === poapId);
      const descriptionId = poap?.id;

      if (!descriptionId) {
        throw new Error('Description ID is undefined');
      }

      const category = poap.categories[categoryIndex];
      const step = category.steps[stepIndex];
      const stepId = step.id;

      await completeStepMutation.mutateAsync({ teamId, stepId, descriptionId });

      setModalVisible(false);
    } catch (error) {
      console.error('Error completing the step:', error);
    }
  };

    // Handle toggling team expansion
    const handleToggleTeam = (teamId) => {
      setExpandedTeam((prevTeamId) => (prevTeamId === teamId ? null : teamId));
      // Reset expanded POAP when team changes
      setExpandedPoap(null);
    };
  
    // Handle toggling POAP expansion
    const handleTogglePoap = (poapId) => {
      setExpandedPoap((prevPoapId) => (prevPoapId === poapId ? null : poapId));
    };
  
    // Open confirmation modal
    const openConfirmationModal = (teamId, poapId, categoryIndex, stepIndex) => {
      setCurrentStep({ teamId, poapId, categoryIndex, stepIndex });
      setModalVisible(true);
    };
  
  // Handle loading and error states
  if (profileLoading || teamsLoading || poapsLoading || stepStatusesLoading) {
    return <Loader />;
  }

  if (profileError || teamsError || poapsError || stepStatusesError) {
    return <div>Error loading data.</div>;
  }
  

  return (
    <div className="mt-3 grid h-full w-full grid-cols-12 gap-5 rounded-[20px]">
      {/* Subject selector moved down */}
      <div className="col-span-12 md:col-span-4 xl:col-span-3">
        <Card extra="w-full h-full p-4">
          <SubjectSelector subjects={[{ id: 1, name: selectedSubject }]} selectedSubject={selectedSubject} onSelect={setSelectedSubject} />
        </Card>
      </div>

      <div className="col-span-12 md:col-span-8 xl:col-span-9">
        <Card extra="w-full h-full p-4">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-bold text-navy-700 dark:text-white">
              Teams in {selectedSubject}
            </h3>
            <div className="relative">
              <input
                type="text"
                placeholder="Search Teams..."
                className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-navy-800 text-navy-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-brand-500"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 dark:text-gray-400">
                <FaSearch />
              </div>
            </div>
          </div>
          <TeamList 
            teams={teamsData || []} 
            expandedTeam={expandedTeam} 
            onToggleTeam={handleToggleTeam} 
            expandedPoap={expandedPoap} 
            onTogglePoap={handleTogglePoap} 
            assignedSteps={assignedSteps} 
            onAssignPoints={openConfirmationModal}
            poaps={poapsData || []} 
            searchQuery={searchQuery}
            selectedStepId={selectedStepId} 
          />
        </Card>
      </div>

      {modalVisible && currentStep && (
        <Modal onClose={() => setModalVisible(false)}>
          <h2 className="text-lg font-semibold text-navy-700 dark:text-white mb-4">
            Confirm Approval
          </h2>
          <p className="text-sm text-gray-600 dark:text-gray-300 mb-6">
            Are you sure you want to approve this step and assign points?
          </p>
          <div className="flex justify-end space-x-3">
            <button
              className="px-4 py-2 bg-gray-300 dark:bg-navy-700 text-gray-800 dark:text-gray-200 rounded-lg hover:bg-gray-400 dark:hover:bg-navy-600 focus:outline-none"
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-brand-500 text-white rounded-lg hover:bg-brand-600 focus:outline-none"
              onClick={handleAssignPoints}
            >
              Confirm
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

const SubjectSelector = ({ subjects, selectedSubject, onSelect }) => (
  <aside className="w-full">
    <h3 className="text-xl font-bold mb-4 text-navy-700 dark:text-white">Subject</h3>
    <div className="space-y-3">
      {subjects.map((subject) => (
        <button
          key={subject.id}
          className={`block w-full px-6 py-2 text-left font-medium rounded-lg transition-colors duration-200 whitespace-nowrap ${
            selectedSubject === subject.name
              ? 'bg-brand-500 text-white'
              : 'bg-gray-200 dark:bg-navy-700 text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-navy-600'
          }`}
          onClick={() => onSelect(subject.name)}
        >
          {subject.name}
        </button>
      ))}
    </div>
  </aside>
);

const TeamList = ({ teams, expandedTeam, onToggleTeam, onTogglePoap, expandedPoap, assignedSteps, onAssignPoints, poaps, searchQuery, selectedStepId }) => {
  const filteredTeams = teams.filter(team => {
    if (typeof team.name !== 'string') {
      console.warn('Team name is not a valid string:', team);
      return false;
    }
    return team.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <div className="space-y-6">
      {filteredTeams.map((team) => (
        <Card key={team.id} extra="w-full h-fit p-4">
          <TeamHeader team={team} expandedTeam={expandedTeam} onToggleTeam={onToggleTeam} />
          {expandedTeam === team.id && (
            <POAPList 
              poaps={poaps} 
              expandedPoap={expandedPoap} 
              onTogglePoap={onTogglePoap} 
              teamId={team.id} 
              assignedSteps={assignedSteps} 
              onAssignPoints={onAssignPoints} 
              selectedStepId={selectedStepId} // Pass selected step to auto-expand
            />
          )}
        </Card>
      ))}
    </div>
  );
};

const TeamHeader = ({ team, expandedTeam, onToggleTeam }) => (
  <button
    className="w-full flex justify-between items-center p-4 rounded-lg hover:bg-gray-100 dark:hover:bg-navy-600 transition-colors"
    onClick={() => onToggleTeam(team.id)}
  >
    <h4 className="text-lg font-semibold text-navy-700 dark:text-white">{team.name}</h4>
    {expandedTeam === team.id ? (
      <FaChevronDown className="text-gray-500 dark:text-gray-300" />
    ) : (
      <FaChevronRight className="text-gray-500 dark:text-gray-300" />
    )}
  </button>
);

const POAPList = ({ poaps, expandedPoap, onTogglePoap, teamId, assignedSteps, onAssignPoints, selectedStepId }) => (
  <div className="mt-4">
    <h5 className="text-md font-semibold text-gray-600 dark:text-gray-400 mb-3">POAPs</h5>
    <div className="space-y-4">
      {poaps.map((poap) => (
        <div
          key={poap.id}
          className="p-3 bg-gray-50 dark:bg-navy-700 rounded-lg border border-gray-200 dark:border-navy-600"
        >
          <POAPHeader poap={poap} expandedPoap={expandedPoap} onTogglePoap={onTogglePoap} />
          {expandedPoap === poap.id && (
            <CategoryList 
              categories={poap.categories} 
              teamId={teamId} 
              poapId={poap.id} 
              assignedSteps={assignedSteps} 
              onAssignPoints={onAssignPoints} 
              selectedStepId={selectedStepId} // Pass selected step to auto-expand
            />
          )}
        </div>
      ))}
    </div>
  </div>
);

const POAPHeader = ({ poap, expandedPoap, onTogglePoap }) => (
  <button
    className="w-full flex justify-between items-center p-4 rounded-lg hover:bg-gray-100 dark:hover:bg-navy-600 transition-colors"
    onClick={() => onTogglePoap(poap.id)}>
    <h6 className="font-medium text-navy-700 dark:text-white">{poap.title}</h6>
    {expandedPoap === poap.id ? (
      <FaChevronDown className="text-gray-500 dark:text-gray-300" />
    ) : (
      <FaChevronRight className="text-gray-500 dark:text-gray-300" />
    )}
  </button>
);

const CategoryList = ({ categories, teamId, poapId, assignedSteps, onAssignPoints, selectedStepId }) => (
  <div className="mt-3">
    {categories.map((category, catIndex) => (
      <div key={`${poapId}-${category.id}`} className="mb-4">
        <h5 className="text-sm font-semibold text-gray-600 dark:text-gray-400 mb-2">{category.name}</h5>
        <ul className="space-y-2">
          {category.steps
            .sort((a, b) => a.id - b.id)
            .map((step, stepIndex) => {
              const key = `${teamId}-${poapId}-${step.id}`;
              return (
                <StepItem 
                  key={key} 
                  step={step} 
                  stepKey={key} 
                  assignedSteps={assignedSteps} 
                  onAssignPoints={() => onAssignPoints(teamId, poapId, catIndex, stepIndex)} 
                  selectedStepId={selectedStepId} // Pass selected step to auto-expand
                />
              );
            })}
        </ul>
      </div>
    ))}
  </div>
);

const StepItem = ({ step, stepKey, assignedSteps, onAssignPoints, selectedStepId }) => (
  <li className={`flex justify-between items-center p-3 bg-white dark:bg-navy-800 rounded-lg shadow-sm ${selectedStepId ===step.id ? 'border-2 border-brand-500' : ''}`}>
    <div>
      <span className="text-sm font-medium text-navy-700 dark:text-white block">{step.step}</span>
      <span className="text-xs text-gray-500 dark:text-gray-400">{step.points} points</span>
    </div>
    <div className="flex items-center space-x-3">
      {assignedSteps[stepKey] ? (
        <FaCheckCircle className="text-green-500" />
      ) : (
        <button
          className="flex items-center bg-gradient-to-r from-brand-500 to-green-500 text-white px-4 py-2 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 focus:outline-none"
          onClick={onAssignPoints}
        >
          <FaPlusCircle className="mr-1" />
          <span>Add Points</span>
        </button>
      )}
    </div>
  </li>
);

export default ManageTeam;
