import { useState, useEffect, useCallback } from "react";
import InputField from "components/fields/InputField";
import { FiSearch, FiUserPlus, FiX } from "react-icons/fi";
import { RiUserHeartFill } from "react-icons/ri";
import { searchUsersBySchool, getUserProfile } from "api";
import debounce from "lodash.debounce";

const roles = [
  "Design Specialist",
  "Technical Specialist",
  "Research & Development Specialist",
  "Documentation & Presentation Specialist",
];

const AddMembers = ({ setLocalMembers, teamData }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [noUserFound, setNoUserFound] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [currentUser, setCurrentUser] = useState(null);

  // Fetch current user profile on mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile();
        setCurrentUser(profile.user);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    fetchUserProfile();
  }, []);

  // Handle search input change with debounce
  const handleSearchChange = useCallback(
    debounce(async (value) => {
      if (!value.trim() || !currentUser) {
        setSearchResults([]);
        setNoUserFound(false);
        return;
      }
  
      try {
        setSearchLoading(true);

        console.log("Excluding current user ID:", currentUser.id); 
  
        // Pass currentUser.id to exclude the current user in backend
        const users = await searchUsersBySchool(value, currentUser.id);
  
        const filteredUsers = users.filter(
          (user) =>
            user.id !== teamData.mentorId && // Exclude mentor
            !selectedMembers.some((member) => member.id === user.id) // Exclude already selected members
        );
  
        if (filteredUsers.length > 0) {
          setSearchResults(filteredUsers);
          setNoUserFound(false);
        } else {
          setSearchResults([]);
          setNoUserFound(true);
        }
      } catch (error) {
        console.error("Error searching user:", error);
        setSearchResults([]);
        setNoUserFound(true);
      } finally {
        setSearchLoading(false);
      }
    }, 300),
    [teamData, currentUser, selectedMembers]
  );

  // Add member to the selected members list
  const handleAddMember = useCallback(
    (username) => {
      if (selectedMembers.length >= 5) {
        setWarningMessage("A team cannot have more than 5 members.");
        return;
      }

      const memberToAdd = searchResults.find(
        (user) => user.username === username
      );

      if (memberToAdd) {
        const alreadyInTeam = selectedMembers.some(
          (member) => member.username === username
        );
        if (alreadyInTeam) {
          setWarningMessage("This user is already in the team.");
          return;
        }

        const newMember = { ...memberToAdd, role: roles[0] }; // Default role
        setSelectedMembers((prevMembers) => [...prevMembers, newMember]);
        setSearchQuery("");
        setSearchResults([]);
        setNoUserFound(false);
        setWarningMessage("");
      }
    },
    [searchResults, selectedMembers]
  );

  // Remove member from the selected members list
  const handleRemoveMember = useCallback(
    (username) => {     
      setSelectedMembers((prevMembers) =>
        prevMembers.filter((member) => member.username !== username)
      );
      setWarningMessage("");
    },
    [selectedMembers]
  );

  // Handle role change for a member
  const handleRoleChange = useCallback((username, newRole) => {
    setSelectedMembers((prevMembers) =>
      prevMembers.map((member) =>
        member.username === username ? { ...member, role: newRole } : member
      )
    );
  }, []);

  // Update local members in the parent component
  useEffect(() => {
    setLocalMembers(selectedMembers);
  }, [selectedMembers, setLocalMembers]);

  return (
    <div className="h-full w-full p-4 md:p-8 bg-white dark:bg-navy-700 rounded-lg shadow-lg">
      <h4 className="flex items-center gap-2 mb-6 text-xl md:text-2xl font-bold text-navy-700 dark:text-white">
        <FiUserPlus className="text-2xl md:text-3xl" /> Add Members
      </h4>

      {warningMessage && (
        <p className="mb-4 text-red-500 dark:text-red-400">{warningMessage}</p>
      )}

      <InputField
        extra="w-full mb-6"
        label="Search Member by Username"
        placeholder="e.g., johndoe"
        id="searchmember"
        type="text"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          handleSearchChange(e.target.value);
        }}
        icon={<FiSearch className="text-xl" />}
      />

      {searchLoading && (
        <div className="text-gray-500 dark:text-gray-400">Searching...</div>
      )}

      {searchQuery && searchResults.length > 0 && (
        <div className="mb-4">
          {searchResults.map((user) => (
            <div
              key={user.username}
              className="p-2 bg-gray-100 dark:bg-navy-800 rounded-md shadow-inner hover:bg-gray-200 dark:hover:bg-navy-600 transition-all duration-300 ease-in-out cursor-pointer"
              onClick={() => handleAddMember(user.username)}
            >
              <p className="text-blue-500 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-600">
                <RiUserHeartFill className="inline mr-2" /> {user.username}
              </p>
            </div>
          ))}
        </div>
      )}

      {searchQuery && noUserFound && (
        <div className="mb-4 p-2 bg-red-100 dark:bg-red-800 rounded-md shadow-inner">
          <p className="text-red-500 dark:text-red-400">User not found</p>
        </div>
      )}

      <div className="space-y-4">
        <h5 className="text-lg font-bold text-navy-700 dark:text-white">
          Selected Members:
        </h5>
        {selectedMembers.length === 0 ? (
          <p className="text-gray-500 dark:text-gray-400">
            No members selected.
          </p>
        ) : (
          <ul className="space-y-2">
            {selectedMembers.map((member) => (
              <li
                key={member.username}
                className="flex items-center justify-between p-2 bg-white dark:bg-navy-800 rounded-md shadow-sm"
              >
                <div className="flex items-center">
                  <RiUserHeartFill className="inline mr-2" />
                  <span className="font-medium">{member.username}</span>
                </div>
                <div className="flex items-center space-x-4">
                  <select
                    value={member.role}
                    onChange={(e) =>
                      handleRoleChange(member.username, e.target.value)
                    }
                    className="bg-white dark:bg-navy-700 border border-gray-300 dark:border-navy-600 rounded-lg text-navy-700 dark:text-white px-3 py-2 text-sm"
                    aria-label={`Select role for ${member.username}`}
                  >
                    {roles.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                  <FiX
                    className="text-red-500 dark:text-red-400 cursor-pointer"
                    onClick={() => handleRemoveMember(member.username)}
                    aria-label={`Remove ${member.username}`}
                  />
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AddMembers;
