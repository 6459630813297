import Card from "components/card";
import CardMenu from "components/card/CardMenu";
import Switch from "components/switch";
import React from "react";

function Notification() {
  const notifications = [
    { id: "switch1", label: "Task assignment notifications" },
    { id: "switch2", label: "Team activity notifications" },
    { id: "switch3", label: "Project update notifications" },
    { id: "switch4", label: "Event reminders notifications" },
    { id: "switch5", label: "System alerts notifications" },
    { id: "switch6", label: "New message notifications" },
    { id: "switch7", label: "Monthly summary reports" },
    { id: "switch8", label: "Newsletter subscriptions" },
    { id: "switch9", label: "Follow notifications" },
  ];

  return (
    <Card extra={"w-full h-full p-3"}>
      <div className="relative mb-3 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Notifications
        </h4>
      </div>
      <div className="flex flex-col gap-4">
        {notifications.map((notification) => (
          <div key={notification.id} className="flex items-center gap-3">
            <Switch id={notification.id} />
            <label
              htmlFor={notification.id}
              className="text-base font-medium text-navy-700 dark:text-white"
            >
              {notification.label}
            </label>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default Notification;
