import React, { useState, useEffect } from 'react';
import {
  IoSearchOutline,
  IoPersonAddOutline,
  IoCloseOutline,
  IoCheckmarkCircle,
  IoAlertCircle,
} from 'react-icons/io5';
import { searchUsersBySchool, addMembersToTeam, getUserProfile } from 'api';


const getImageUrl = (imageUrl) => {
  return imageUrl ? `${process.env.REACT_APP_API_BASE_URL}${imageUrl}` : 'https://via.placeholder.com/150';
};

const SearchModal = ({ onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState({});
  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile();
        setCurrentUser(response.user);
        setTeamId(response.user.team?.id);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleSearchChange = async (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.trim() === '') {
      setSearchResults([]);
      return;
    }

    setIsLoading(true);
    try {
      const users = await searchUsersBySchool(event.target.value);
      const filteredUsers = users.filter(
        (user) => user.username !== currentUser?.username
      );
      setSearchResults(filteredUsers);
    } catch (error) {
      console.error('Error searching users:', error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoleChange = (personId, role) => {
    setSelectedRoles((prevRoles) => ({
      ...prevRoles,
      [personId]: role,
    }));
  };

  const handleAddPerson = async (personId) => {
    const role = selectedRoles[personId];

    if (!role) {
      setStatusMessage({
        type: 'error',
        message: 'Please select a role before adding the member.',
      });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
      return;
    }

    try {
      await addMembersToTeam(teamId, [{ id: personId, teamRole: role }]);
      setStatusMessage({
        type: 'success',
        message: 'Person added to the team successfully!',
      });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
      // Optionally, remove the person from the search results
      setSearchResults((prevResults) =>
        prevResults.filter((person) => person.id !== personId)
      );
    } catch (error) {
      setStatusMessage({
        type: 'error',
        message: 'Error adding person to the team.',
      });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
      console.error('Error adding person to the team:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-70 overflow-y-auto">
      <div className="bg-white dark:bg-navy-800 rounded-lg w-11/12 max-w-2xl animate-slideInUp shadow-lg">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Add Team Member</h3>
          <button onClick={onClose} className="focus:outline-none">
            <IoCloseOutline className="text-2xl text-gray-800 dark:text-white" />
          </button>
        </div>

        {/* Search Input */}
        <div className="px-4 py-2">
          <div className="relative">
            <IoSearchOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-400 dark:text-gray-500" />
            <input
              type="text"
              className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-navy-700 text-gray-800 dark:text-white focus:border-blue-500 dark:focus:border-blue-300 focus:outline-none"
              placeholder="Search for a person..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {/* Search Results */}
        <div className="p-4 overflow-y-auto max-h-96">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <div className="loader"></div>
            </div>
          ) : searchResults.length > 0 ? (
            searchResults.map((person) => (
              <div
                key={person.id}
                className="flex flex-col md:flex-row items-start md:items-center justify-between p-4 mb-2 rounded-lg bg-gray-50 dark:bg-navy-700 hover:bg-gray-100 dark:hover:bg-navy-600 transition-colors"
              >
                <div className="flex items-center mb-2 md:mb-0 w-full md:w-auto">
                  <img
                    src={getImageUrl(person.imageUrl)}
                    alt=""
                    className="w-12 h-12 rounded-full object-cover shadow-lg mr-4"
                  />
                  <div className="truncate">
                    <p className="text-sm font-medium text-gray-800 dark:text-white">
                      {person.firstName} {person.lastName}
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">{person.username}</p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-2 items-stretch w-full md:w-auto">
                <select
                  className="rounded-xl border border-gray-200 dark:border-white/10 bg-white dark:bg-navy-900 text-navy-700 dark:text-white focus:border-orange-400 dark:focus:border-orange-500 focus:outline-none text-sm w-full sm:w-auto px-4 py-2 transition duration-200 ease-in-out hover:bg-gray-50 dark:hover:bg-navy-800 shadow-md"
                  value={selectedRoles[person.id] || ''}
                  onChange={(e) => handleRoleChange(person.id, e.target.value)}
                >
                  <option value="" disabled className="text-gray-500 dark:text-gray-400">
                    Select Role
                  </option>
                  <option value="Design Specialist" className="hover:bg-orange-100 dark:hover:bg-orange-700">
                    Design Specialist
                  </option>
                  <option value="Technical Specialist" className="hover:bg-orange-100 dark:hover:bg-orange-700">
                    Technical Specialist
                  </option>
                  <option value="Research & Development Specialist" className="hover:bg-orange-100 dark:hover:bg-orange-700">
                    Research & Development Specialist
                  </option>
                  <option value="Documentation & Presentation Specialist" className="hover:bg-orange-100 dark:hover:bg-orange-700">
                    Documentation & Presentation Specialist
                  </option>
                </select>



                  <button
                    className="flex items-center justify-center px-3 py-2 text-sm font-medium text-white bg-orange-500 rounded-xl hover:bg-brand-900 active:bg-dark transition duration-200 dark:bg-orange-500 dark:text-white dark:hover:bg-orange-600 dark:active:bg-orange-500 w-full sm:w-auto"
                    onClick={() => handleAddPerson(person.id)}
                  >
                    <IoPersonAddOutline className="mr-1" />
                    Add
                  </button>
                </div>
              </div>
            ))
          ) : searchTerm.trim() !== '' ? (
            <div className="p-4 text-center text-gray-500">
              No users found.
            </div>
          ) : (
            <div className="p-4 text-center text-gray-500">
              Start typing to search for users.
            </div>
          )}
        </div>

        {/* Status Message */}
        {statusMessage.message && (
          <div className={`p-4 flex items-center justify-center ${statusMessage.type === 'success' ?
                           'bg-green-100 dark:bg-green-900 text-green-600 dark:text-green-400' : 
                           'bg-red-100 dark:bg-red-900 text-red-600 dark:text-red-400'}`}>

            {statusMessage.type === 'success' ? (
              <IoCheckmarkCircle className="mr-2 text-2xl" />
            ) : (
              <IoAlertCircle className="mr-2 text-2xl" />
            )}
            <p className="text-sm">{statusMessage.message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchModal;
