import { useQuery } from "@tanstack/react-query";
import Balance from "./components/Balance";
import CourseTable from "./components/CourseTable";
import Invoice from "./components/Invoice";
import PaymentMethod from "./components/PaymentMethod";
import YourCard from "./components/YourCard";
import YourTransaction from "./components/YourTransaction";
import YourTransfers from "./components/YourTransfer";

import { fetchReferralCodesByUserId ,fetchBillingsByUserId, fetchInvoicesByUserId, getUserProfile, fetchCoursesByUserId } from "api";
import Loader from "components/loaders/Loader";

const Billing = () => {

  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    error: userProfileError,
  } = useQuery({
    queryKey: ["userProfile"],
    queryFn: getUserProfile,
  });

  const userId = userProfile?.user.id;


  // Queries for each data set
  const {
    data: billings = [],
    isLoading: isBillingsLoading,
    error: billingsError,
  } = useQuery({
    queryKey: ["billings", userId],
    queryFn: () => fetchBillingsByUserId(userId),
    enabled: !!userId,
  });

  const {
    data: invoices = [],
    isLoading: isInvoicesLoading,
    error: invoicesError,
  } = useQuery({
    queryKey: ["invoices", userId],
    queryFn: () => fetchInvoicesByUserId(userId),
    enabled: !!userId,
  });

  const {
    data: courses = [],
    isLoading: isCoursesLoading,
    error: coursesError,
  } = useQuery({
    queryKey: ["courses", userId],
    queryFn: () => fetchCoursesByUserId(userId),
    enabled: !!userId,
  });
  // Log the fetched courses data
console.log("Fetched courses:", courses);

  const {
    data: couponsData = [],
    isLoading: isCouponsLoading,
    error: couponsError,
  } = useQuery({
    queryKey: ["coupons", userId],
    queryFn: () => fetchReferralCodesByUserId(userId),
    enabled: !!userId,
  });

  const coupons = Array.isArray(couponsData) ? couponsData.map((coupon) => ({
    title: `${coupon.discount_percentage}% Off`,
    expires: coupon.updatedAt,
    isActive: coupon.max_usage > coupon.times_used,
  })) : [];

  // Aggregate all loading states
  const isLoading =
  isUserProfileLoading ||
  (!userProfileError && !userId); // Shows loader if userProfile is loading

  // Handle loading state globally
  if (isLoading) {
  return <Loader />;
  }

  // Log errors to the console, but continue rendering components with available data
  if (userProfileError) console.error("Error loading user profile:", userProfileError);
  if (billingsError) console.error("Error loading billings:", billingsError);
  if (invoicesError) console.error("Error loading invoices:", invoicesError);
  if (coursesError) console.error("Error loading courses:", coursesError);
  if (couponsError) console.error("Error loading coupons:", couponsError);


  // Define table columns and data
  const columnsData = [
    {
      Header: "COURSE NAME",
      accessor: "name",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
  ];

  /* // Transform courses data for the table
  const tableData = courses.map((subscription) => ({
    name: subscription.title,
    status: subscription.status,
    progress: Math.floor(Math.random() * 100), // Replace with actual progress if available
  })); */

  const tableData = courses
  .filter(subscription => subscription.status === "active") // Filter only purchased courses
  .map((subscription) => ({
    name: subscription.title,
    status: subscription.status,
    progress: Math.floor(Math.random() * 100), // Replace with actual progress if available
  }));



  return (
    <div className="mt-3 w-full">
      <div className="mb-5 grid grid-cols-1 gap-5 xl:grid-cols-3">
        <YourCard />
        <div className="h-full w-full rounded-[20px]">
          <Balance transactions={billings}/>
          <PaymentMethod />
        </div>
        <Invoice invoices={invoices}/>
      </div>
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-3">
        <div>
          <YourTransaction transactions={billings}/>
        </div>
        <div>  
                  
        <YourTransfers appliedCoupons={coupons} />
        </div>
        <div>         
          <CourseTable columnsData={columnsData} tableData={tableData} />
        </div>
      </div>
    </div>
  );
};

export default Billing;
