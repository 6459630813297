import React, { useState, useEffect } from "react";
import { IoCheckmarkCircle, IoClose } from "react-icons/io5";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import MpesaLogo from "../../assets/img/ecommerce/Mpesa.png";
import { subscribeToCourse, validateReferralCode } from "api";
import AnimatedCheckmark from "components/dataDisplay/AnimatedCheckmark";

const SubscriptionModal = ({ onClose, parentName, courseId, userId, mentorId }) => {
  const [currency, setCurrency] = useState("USD");
  const [referralCode, setReferralCode] = useState("");
  const [discountApplied, setDiscountApplied] = useState(false);
  const [referralVisible, setReferralVisible] = useState(false);
  const [originalAmount] = useState(10);
  const [discountedAmount, setDiscountedAmount] = useState(originalAmount); // Holds discounted value
  const [finalAmount, setFinalAmount] = useState(originalAmount);
  const [exchangeRates, setExchangeRates] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [showRefPrompt, setShowRefPrompt] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

  const currencyOptions = ["USD", "EUR", "GBP", "KES", "JPY", "INR"];
  const apiKey = "your_api_key_here"; // Replace with your actual API key

  // Fetch exchange rates
  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch(
          `https://api.fxratesapi.com/latest?api_key=${apiKey}`
        );
        const data = await response.json();
        setExchangeRates(data.rates);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };
    fetchExchangeRates();
  }, []);

 

  const { refetch, isLoading: isDiscountLoading } = useQuery({
    queryKey: ["validateReferralCode", referralCode, originalAmount],
    queryFn: async () => {
      if (!referralCode) throw new Error("No referral code provided");
      const { discountAmount, finalPrice } = await validateReferralCode(referralCode, originalAmount);
      return { discountAmount, finalPrice };
    },
    enabled: false,
    onSuccess: (data) => {
      setDiscountedAmount(data.finalPrice); // Update discountedAmount to the discounted price
      setDiscountApplied(true);
      console.log("Discount applied, new discounted amount:", data.finalPrice);
    },
    onError: () => {
      alert("Invalid or expired referral code.");
      setDiscountApplied(false);
    },
  });

   // Update final amount based on selected currency and apply discount if any
   useEffect(() => {
    const baseAmount = discountedAmount;
    if (currency !== "USD" && exchangeRates[currency]) {
      const exchangeRate = exchangeRates[currency];
      setFinalAmount((baseAmount * exchangeRate).toFixed(2));
      console.log("Final amount updated with exchange rate:", baseAmount * exchangeRate);
    } else {
      setFinalAmount(baseAmount.toFixed(2));
      console.log("Final amount updated without exchange rate:", baseAmount);
    }
  }, [currency, exchangeRates, discountedAmount]);

  const handleApplyReferralCode = () => {
    refetch();
  };

  const handleSubscribeClick = () => {
    setShowRefPrompt(true);
  };

  const handleConfirmReference = async () => {
    if (!referenceNumber) {
      alert("Please enter the payment reference number.");
      return;
    }

    setIsProcessing(true);
    try {
      const subscriptionData = {
        userId,
        courseId,
        mentorId,
        referenceNumber,
      };

      await subscribeToCourse(subscriptionData);

      setSubscriptionSuccess(true);
    } catch (error) {
      console.error("Error during subscription confirmation:", error);
      alert("Error processing your subscription. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="relative bg-white dark:bg-navy-800 rounded-xl shadow-lg w-full max-w-md p-6">
        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white"
        >
          <IoClose size={24} />
        </button>

        {/* Modal Title */}
        <h2 className="text-xl font-semibold text-center text-navy-700 dark:text-white">
          Subscribe to {parentName}
        </h2>

        {/* Success Message */}
        {subscriptionSuccess ? (
          <div className="flex flex-col items-center mt-6">
            <AnimatedCheckmark />
            <p className="mt-4 text-center text-gray-700 dark:text-gray-300">
              Subscription successful! Your transaction is under review. The mentor will share course details shortly, and additional information will be sent to your email.
            </p>
            <button
              onClick={onClose}
              className="w-full mt-4 py-3 text-white bg-orange-600 rounded-md hover:bg-orange-900 transition dark:bg-navy-800 dark:hover:bg-navy-600"
            >
              Close
            </button>
          </div>
        ) : (
          <>
            {/* Pricing Section */}
            <div className="mt-6">
              <div className="flex justify-between items-center">
                <motion.div
                  className="text-3xl font-bold text-navy-700 dark:text-white"
                  initial={{ opacity: 0.9, scale: 1 }}
                  animate={{ opacity: discountApplied ? 1 : 0.9, scale: discountApplied ? 1.1 : 1 }}
                  transition={{ duration: 0.4 }}
                >
                  {currency} {finalAmount}
                </motion.div>
                <select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="py-2 px-3 border rounded-md bg-gray-100 dark:bg-navy-700 dark:text-white focus:outline-none"
                >
                  {currencyOptions.map((curr) => (
                    <option key={curr} value={curr}>
                      {curr}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Payment Instructions */}
            <div className="mt-6">
              <h3 className="text-lg font-medium text-navy-700 dark:text-white">Payment Details</h3>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                Send payment to:
              </p>
              <div className="mt-4 bg-gray-50 dark:bg-navy-700 p-4 rounded-md">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-700 dark:text-gray-200">
                      <strong>Paybill:</strong> 400200
                    </p>
                    <p className="text-sm text-gray-700 dark:text-gray-200">
                      <strong>Account:</strong> 01105573178001
                    </p>
                  </div>
                  <img src={MpesaLogo} alt="Mpesa" className="h-8 w-auto" />
                </div>
              </div>
            </div>

            {/* Referral Code Section */}
            <div className="mt-6">
              <button
                className="text-sm text-orange-500 hover:underline"
                onClick={() => setReferralVisible(!referralVisible)}
              >
                {referralVisible ? "Hide Referral Code" : "Have a referral code?"}
              </button>
              {referralVisible && (
                <div className="mt-2">
                  <input
                    type="text"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                    placeholder="Enter referral code"
                    className="w-full p-2 border rounded-md dark:bg-navy-900 dark:border-navy-700 dark:text-white mt-1"
                  />
                  <button
                    onClick={handleApplyReferralCode}
                    disabled={isDiscountLoading}
                    className="w-full py-2 mt-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition"
                  >
                    {isDiscountLoading ? "Applying..." : "Apply Code"}
                  </button>
                  {discountApplied && (
                    <p className="mt-2 text-sm text-green-600">Discount applied!</p>
                  )}
                </div>
              )}
            </div>

            {/* Reference Number Prompt */}
            {showRefPrompt ? (
              <div className="mt-6">
                <label
                  htmlFor="reference-number"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Payment Reference Number
                </label>
                <input
                  type="text"
                  id="reference-number"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                  placeholder="e.g., MPESA12345"
                  className="mt-1 w-full p-2 border rounded-md dark:bg-navy-900 dark:border-navy-700 dark:text-white"
                />
                <button
                  onClick={handleConfirmReference}
                  disabled={isProcessing}
                  className={`w-full py-3 mt-4 text-white rounded-md transition ${
                    isProcessing
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-green-500 hover:bg-green-600"
                  }`}
                >
                  {isProcessing ? "Processing..." : "Confirm Payment"}
                </button>
              </div>
            ) : (
              <button
                onClick={handleSubscribeClick}
                className="w-full py-3 mt-6 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition"
              >
                Subscribe
              </button>
            )}
          </>
        )}

        {/* Cancel Button */}
        {!subscriptionSuccess && (
          <button
            onClick={onClose}
            className="w-full py-3 mt-4 text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-white transition"
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default SubscriptionModal;
