import React, { useState } from 'react';
import TeamProject from './components/TeamProject';
import Banner from './components/Banner';
import NftBanner3 from 'assets/img/nfts/NftBanner3.png';
import AvatarSimmple from 'assets/img/avatars/avatarSimmmple.png';
import Tasks from '../main/applications/kanban';
import Calendar from '../main/applications/calendar';
import Team from './Team/index';
import Loader from 'components/loaders/Loader';

const TeamsHome = () => {
  const [activeTab, setActiveTab] = useState(1);

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <Team />;
      case 2:
        return <Calendar />;
      default:
        return <Team />;
    }
  };

  return (
    <div className='mt-3 flex h-full w-full flex-col gap-5 rounded-2xl xl:flex-row'>
      {/* Banner Section */}
      <div className='h-full w-full rounded-2xl'>
        <div className='relative group'>
          <Banner
            image={NftBanner3}
            profile={AvatarSimmple}
            wallet="MyWallet420"
            address='simmple.web'
            name='Simmple'
            dat='Joined 17 Nov 2019'
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
          <div className='absolute top-0 right-0 p-4'>
            <div className='hidden lg:flex space-x-4'>
              <button
                className={`px-4 py-2 rounded-lg ${activeTab === 1 ? 'bg-brand-500 text-white' : 'bg-gray-100 dark:bg-navy-800 text-gray-600 dark:text-white'}`}
                onClick={() => setActiveTab(1)}
              >
                Team
              </button>
              <button
                className={`px-4 py-2 rounded-lg ${activeTab === 2 ? 'bg-brand-500 text-white' : 'bg-gray-100 dark:bg-navy-800 text-gray-600 dark:text-white'}`}
                onClick={() => setActiveTab(2)}
              >
                Calendar
              </button>
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className='mt-5 w-full grid grid-cols-1 gap-5'>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default TeamsHome;
