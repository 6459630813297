import { Navigate } from "react-router-dom";
import { useAuth } from "./authContext";
import { Permissions } from "utils/permissions";

const ProtectedRoute = ({ element, requiredPermission, requiredPermissions = [] }) => {
  const { isAuthenticated, user } = useAuth();
  console.log("Is Authenticated:", isAuthenticated); // Log authentication status
  console.log("User:", user); // Log user data
  console.log("Required Permission:", requiredPermission); // Log required permission
  console.log("Required Permissions:", requiredPermissions); // Log required permissions

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  // Check for single permission
  if (requiredPermission && !user.permissions.includes(requiredPermission)) {
    console.warn("Access denied: insufficient permissions");
    return <Navigate to="/auth/sign-in" replace />;
  }

  // Check for multiple permissions
  if (
    requiredPermissions.length > 0 && 
    !requiredPermissions.some(permission => user.permissions.includes(permission))
  ) {
    console.warn("Access denied: insufficient permissions");
    return <Navigate to="/auth/sign-in" replace />;
  }

  return element;
};

export default ProtectedRoute;
