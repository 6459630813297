import { MdDashboard, MdApps } from "react-icons/md";
import SearchIcon from "components/icons/SearchIcon";

const Search = ({ onSearchChange, onCategoryChange, onSortChange }) => {
  return (
    <div className="mx-auto mt-5 flex w-full gap-1 overflow-hidden md:!gap-6 xl:w-[96%]">
      {/* Search Bar */}
      <div className="flex h-[45px] w-full rounded-xl border !border-gray-200 dark:!border-none dark:!bg-navy-700 md:w-[870px] xl:w-full">
        <SearchIcon className="dark:text-white" />
        <input
          placeholder="Search Courses..."
          className="!w-full rounded-xl text-sm font-medium outline-none dark:bg-navy-700 dark:text-white sm:w-[50px] xl:!w-full"
          type="text"
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>

      {/* Course Category Filter */}
      <div className="w-full rounded-xl border !border-gray-200 px-3 text-sm font-medium text-gray-600 outline-none dark:!border-none dark:bg-navy-700 sm:w-full md:w-fit">
        <select
          className="h-[45px] w-full rounded-xl text-sm font-medium text-gray-600 outline-none dark:bg-navy-700 md:w-fit md:pr-8 xl:pr-20"
          name="category"
          id="category"
          onChange={(e) => onCategoryChange(e.target.value)}
        >
          <option value="all">All Categories</option>
          <option value="3D Printing">3D Printing</option>
          <option value="Robotics">Robotics</option>
          <option value="Artificial Intelligence">Artificial Intelligence</option>
          <option value="Arduino">Arduino</option>
        </select>
      </div>

      {/* Sort by Price or Popularity */}
      <div className="w-full rounded-xl border !border-gray-200 px-3 text-sm font-medium text-gray-600 outline-none dark:!border-none dark:bg-navy-700 md:w-fit">
        <select
          className="h-[45px] w-full rounded-xl text-sm font-medium text-gray-600 outline-none dark:bg-navy-700 md:w-fit md:pr-8 xl:pr-20"
          name="sort"
          id="sort"
          onChange={(e) => onSortChange(e.target.value)}
        >
          <option value="popularity">Sort by Popularity</option>
          <option value="price-low-high">Price: Low to High</option>
          <option value="price-high-low">Price: High to Low</option>
          <option value="duration">Sort by Duration</option>
        </select>
      </div>

      {/* Layout Options */}
      <button className="flex items-center justify-center rounded-xl border !border-gray-200 p-3 text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-50 dark:!border-none dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
        <MdDashboard />
      </button>
      <button className="flex items-center justify-center rounded-xl border !border-gray-200 p-3 text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-50 dark:!border-none dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
        <MdApps />
      </button>
    </div>
  );
};

export default Search;
