import React from "react";

const Chat = ({ photo, active, name, time, message, last, isMentor }) => {
  return (
    <div
      className={`flex w-full items-center gap-3 px-2 ${
        last ? "pt-6 pb-3" : "border-b border-gray-100 py-6"
      } cursor-pointer dark:border-navy-700 lg:cursor-pointer`}
    >
      <div className="relative h-[50px] !w-[60px] rounded-full">
        <img className="h-full w-full rounded-full object-cover" src={photo} alt="Profile" />
        <div
          className={`absolute bottom-0 right-0 h-4 w-4 rounded-full border-2 border-white ${
            active ? "bg-green-500" : "bg-red-500"
          }`}
        ></div>
      </div>

      <div className="w-full">
        <div className="flex w-full items-center justify-between">
          <h1 className="text-xl font-bold capitalize text-navy-700 dark:text-white">
            {name}
            {isMentor && (
              <span className="ml-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white text-xs px-2 py-1 rounded-full">
                Mentor
              </span>
            )}
          </h1>
          <h4 className="text-sm font-medium text-gray-400 dark:text-gray-100">{time}</h4>
        </div>
        <p className="text-sm font-normal tracking-normal text-navy-700 dark:text-white">
          {message}
        </p>
      </div>
    </div>
  );
}

export default Chat;
