import React, { useEffect, useState } from 'react';
import { fetchTeamRankings } from 'api';

const TeamRanking = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadTeams = async () => {
      try {
        const fetchedTeams = await fetchTeamRankings();
        console.log('Fetched Teams:', fetchedTeams); // Debugging log
        setTeams(fetchedTeams);
      } catch (error) {
        setError('Error fetching team rankings');
      } finally {
        setLoading(false);
      }
    };
  
    loadTeams();
  }, []);
  

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-lg font-semibold text-gray-700 dark:text-gray-300">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-lg font-semibold text-red-500">{error}</div>
      </div>
    );
  }

  if (teams.length === 0) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-lg font-semibold text-gray-700 dark:text-gray-300">
          No teams have been ranked yet.
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center p-5 bg-white dark:bg-navy-800 rounded-lg shadow-lg dark:shadow-none">
      <h2 className="mb-4 text-xl font-bold text-center text-navy-700 dark:text-white">Leaderboard</h2>
      <div className="overflow-x-auto w-full">
        <table className="w-full text-sm text-left text-gray-700 dark:text-gray-300">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3">Team</th>
              <th scope="col" className="px-6 py-3 text-right">Points</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team, index) => (
              <tr 
                key={index} 
                className={`border-b dark:bg-navy-700 dark:border-gray-600 ${index % 2 ? 'bg-gray-50' : 'bg-white'} dark:text-gray-300`}
              >
                <td className="px-6 py-4 font-medium text-gray-900 dark:text-white">{team.name}</td>
                <td className="px-6 py-4 text-right">{team.points}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TeamRanking;
