import {jwtDecode} from 'jwt-decode';

export function saveToken(token, rememberMe) {
  localStorage.setItem('token', token);
  if (rememberMe) {
    localStorage.setItem('rememberMe', 'true');
  } else {
    localStorage.removeItem('rememberMe');
  }
}

export function getToken() {
  return localStorage.getItem('token');
}

export function removeToken() {
  localStorage.removeItem('token');
  localStorage.removeItem('rememberMe');
}

export function isTokenExpired(token) {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000 < Date.now();
  } catch (e) {
    return true;
  }
}
