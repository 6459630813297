import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdChevronLeft, MdChevronRight, MdPersonAdd, MdAddBox, MdGroupAdd } from "react-icons/md";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Card from "components/card";
import SearchModal from "./SearchModal";
import CreateTeam from "./CreateTeam";
import ErrorAlerts from "components/alerts/ErrorAlerts";
import AnimatedText from "./AnimatedText";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_API_BASE_URL, { transports: ["websocket"] });

const UserReportsTable = (props) => {
  const { columnsData, tableData, isModalOpen, toggleModal, selectedTeam, userProfile } = props;
  const [isCreateTeamModalOpen, setCreateTeamModalOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [updatedTableData, setUpdatedTableData] = useState(tableData);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => updatedTableData, [updatedTableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    initialState,
    setPageSize,
    state,
  } = tableInstance;
  initialState.pageSize = 6;

  const { pageSize } = state;

  const navigate = useNavigate();

  const handleNewProject = () => {
    navigate("/admin/new-project");
  };

  const handleCreateTeam = () => {
    if (userProfile?.user?.teamRole === "Team Leader" && selectedTeam) {
      setErrorMessages(["You are already leading a team and cannot create another."]);
    } else {
      navigate("/admin/create-team");
    }
  };

  const handleCloseCreateTeam = () => {
    setCreateTeamModalOpen(false);
  };

  const handleCloseErrorAlert = () => {
    setErrorMessages([]);
  };

  // Real-time user status updates
  useEffect(() => {
    socket.on("userStatusUpdate", ({ userId, status }) => {
      setUpdatedTableData((prevData) =>
        prevData.map((user) => (user.id === userId ? { ...user, status } : user))
      );
    });

    return () => {
      socket.off("userStatusUpdate");
    };
  }, []);

  // Determine if the current user is the team leader or does not belong to any team
  const canAccessButtons = userProfile?.user?.teamRole === "Team Leader" || !selectedTeam;

  return (
    <>
      {errorMessages.length > 0 && (
        <ErrorAlerts errors={errorMessages} onClose={handleCloseErrorAlert} />
      )}
      <Card extra="w-full px-3 overflow-x-auto lg:overflow-x-hidden">
        <div
          className={`mb-4 flex flex-col lg:flex-row justify-end space-y-4 lg:space-y-0 lg:space-x-4 mt-4 ${
            !selectedTeam ? "pb-80" : ""
          }`}
        >
          {canAccessButtons && (
            <>
              <button
                onClick={handleCreateTeam}
                className="flex items-center justify-center space-x-2 rounded-xl bg-orange-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-orange-400 dark:text-white dark:hover:bg-orange-300 dark:active:bg-orange-200"
              >
                <MdGroupAdd />
                <span>Create Team</span>
              </button>
              <button
                onClick={toggleModal}
                className="flex items-center justify-center space-x-2 rounded-xl border-2 border-orange-500 px-5 py-3 text-base font-medium text-orange-500 transition duration-200 hover:bg-orange-600/5 active:bg-orange-700/5 dark:border-orange-400 dark:bg-orange-400/10 dark:text-white dark:hover:bg-orange-300/10 dark:active:bg-orange-200/10"
              >
                <MdPersonAdd />
                <span>Add Member</span>
              </button>
              <button
                onClick={handleNewProject}
                className="flex items-center justify-center space-x-2 rounded-xl bg-gradient-to-br from-[#FFC837] to-[#FF6108] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FFC837]/50"
              >
                <MdAddBox />
                <span>New Project</span>
              </button>
            </>
          )}
        </div>

        {selectedTeam ? (
          <table {...getTableProps()} className="w-full">
            <thead className="w-full">
              {headerGroups.map((headerGroup, index) => (
                <tr
                  className="items-center border-b border-gray-200 dark:!border-white/10"
                  {...headerGroup.getHeaderGroupProps()}
                  key={index}
                >
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-[19px] pt-[32px] pb-[12px] text-left text-xs tracking-wide text-gray-600"
                      key={index}
                    >
                      <div className="text-xs font-bold">{column.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="w-full">
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    className="border-b border-gray-200 dark:!border-white/10"
                    {...row.getRowProps()}
                    key={index}
                  >
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "FULL NAME") {
                        const imageUrl = cell.row.original.imageUrl;
                        const serverUrl = process.env.REACT_APP_API_BASE_URL;
                        data = (
                          <div className="flex w-full items-center gap-[14px]">
                            <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-blue-300">
                              <img
                                className="h-full w-full rounded-full object-cover"
                                src={
                                  imageUrl
                                    ? `${serverUrl}${imageUrl}`
                                    : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                                }
                                alt={cell.value}
                              />
                            </div>
                            <p className="font-medium text-navy-700 dark:text-white">{cell.value}</p>
                          </div>
                        );
                      } else if (cell.column.Header === "STATUS") {
                        // Displaying status as a badge
                        const status = cell.row.original.status;
                        data = (
                          <div
                            className={`inline-flex items-center px-3 py-1 text-sm font-semibold rounded-full border ${
                              status === "Online"
                                ? "border-green-600 text-green-600"
                                : "border-red-500 text-red-500"
                            }`}
                            style={{ minWidth: "100px", justifyContent: "center" }}
                          >
                            {status || "Offline"}
                          </div>
                        );
                        
                      } else {
                        data = (
                          <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="mt-[20px] px-4 py-[16px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="flex justify-center items-center min-h-[60vh]">
            <AnimatedText />
          </div>
        )}
      </Card>

      {isCreateTeamModalOpen && (
        <CreateTeam isOpen={isCreateTeamModalOpen} onClose={handleCloseCreateTeam} />
      )}
      {isModalOpen && <SearchModal onClose={toggleModal} />}
    </>
  );
};

export default UserReportsTable;
