import React, { useState, useEffect } from "react";
import Loader from "components/loaders/Loader";
import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "./components/Storage";
import Upload from "./components/Upload";
import { useNavigate } from "react-router-dom"; 
import { MdSettings } from "react-icons/md"; 
import MentorInfo from "./components/MentorInfo";
import MentorNotifications from "./components/MentorNotifications";
import MentorsBadge from "./components/MentorsBadge";
import { getUserProfile, fetchCurrentDescription, fetchPoapDescriptions, fetchSpecificStepStatus, uploadProfilePic } from "api"; 
import MentorBanner from "./components/MentorBanner";

const ProfileOverview = () => {
  const navigate = useNavigate(); 
  const [role, setRole] = useState(null); 
  const [userProfile, setUserProfile] = useState(null); 
  const [poapData, setPoapData] = useState({ tasksCompleted: 0, poapLevel: 'Explorer', poapCount: 0});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const profile = await getUserProfile();
        setUserProfile(profile.user);
        setRole(profile.user.role);

        if (profile.user.team) {
          const teamId = profile.user.team.id;
          const poapDescriptions = await fetchPoapDescriptions();
          const currentDescription = await fetchCurrentDescription(teamId);

          // Calculate completed POAPs and tasks
          let completedPoaps = poapDescriptions.filter(poap => poap.id < currentDescription.descriptionId);
          let poapCount = completedPoaps.length;
          let poapLevel = poapCount > 0 ? completedPoaps[poapCount - 1].title : 'Explorer';

          let tasksCompleted = 0;
          for (const poap of poapDescriptions) {
            for (const category of poap.categories) {
              for (const step of category.steps) {
                const stepStatus = await fetchSpecificStepStatus(teamId, poap.id, step.id);
                if (stepStatus.status === 'completed') {
                  tasksCompleted++;
                }
              }
            }
          }

          // Set the POAP data
          setPoapData({ tasksCompleted, poapLevel, poapCount });
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const handleImageChange = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('profilePic', file);

      try {
        const response = await uploadProfilePic(formData);
        setUserProfile((prevProfile) => ({ ...prevProfile, imageUrl: response.imageUrl }));
        alert("Profile picture updated successfully!");
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        alert("Failed to upload profile picture");
      }
    }
  };



  const handleSettingsClick = () => {
    navigate("/admin/main/account/settings"); // Replace with your actual settings route
  };

  if (loading) return <Loader />;

  return (
    <div className="flex w-full flex-col gap-5 lg:gap-5">
      <div className="w-full mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          {role === "mentor" ? (
            <MentorBanner userProfile={userProfile} onChangeProfilePicture={handleImageChange}/>
          ) : (
            <Banner userProfile={userProfile} poapData={poapData} onChangeProfilePicture={handleImageChange}/>
          )}
        </div>

        <div className="col-span-3 lg:!mb-0">
          {role === "mentor" ? <MentorsBadge /> : <Storage />}
        </div>
        <div className="z-0 col-span-5 lg:!mb-0">
          {role === "mentor" ? <MentorInfo userProfile={userProfile} /> : <Upload />}
        </div>
      </div>

      <div className="mb-4 grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Project />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <General />
        </div>

        <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
          {role === "mentor" ? (
            <MentorNotifications userProfile={userProfile} />
          ) : (
            <Notification />
          )}
        </div>
      </div>

      <div className="flex justify-end mt-5">
        <button
          onClick={handleSettingsClick}
          className="flex items-center justify-center rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          <MdSettings className="mr-2 text-xl" /> Change Settings
        </button>
      </div>
    </div>
  );
};

export default ProfileOverview;
